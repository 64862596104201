export const constants = {
  FB_OWNERS: 'owners',
  FB_ADMINS: 'admins',
  FB_ADMINS_NEW: 'admins_new',
  FB_ACTIVITY_LOGS: 'activity_logs',

  FB_CLIENTS: 'clients',
  FB_CLIENTS_IMPORTS: 'clients_imports',
  FB_CLIENTS_REMOVED: 'clients_removed',
  FB_CLIENTS_APPRAISALS: 'clients_appraisals',
  FB_CLIENTS_SCORE_CARD: 'clients_score_card',
  FB_CLIENTS_NOTIFICATIONS: 'clients_notifications',
  FB_CLIENTS_NOTIFICATIONS_HISTORY: 'clients_notifications_history',

  FB_MPESA_B2C_TRANSACTIONS: 'mpesa_b2c_transactions',
  FB_MPESA_C2B_TRANSACTIONS: 'mpesa_c2b_transactions',
  FB_MPESA_STK_TRANSACTIONS: 'mpesa_stk_transactions',
  FB_MPESA_MANUAL_TRANSACTIONS: 'mpesa_manual_transactions',
  FB_MPESA_PAYMENT_REQUESTS: 'payment_requests',
  FB_USSD_SESSIONS: 'ussd_sessions',

  FB_LOANS: 'loans', // new, active, overdue, outsourced, written_off
  FB_LOANS_REPAID: 'loans_repaid',
  FB_LOANS_REMOVED: 'loans_removed', // cancelled, declined
  FB_LOANS_HISTORY: 'loans_history',
  FB_LOANS_HISTORY_ITEMS: 'loans_history_items',
  FB_LOANS_INSTALMENTS: 'loans_instalments',
  FB_LOANS_INSTALMENTS_REMOVED: 'loans_instalments_removed',
  FB_LOANS_INSTALMENTS_REPAID: 'loans_instalments_repaid',
  FB_LOANS_EXCISE_DUTY: 'loans_excise_duty',
  FB_LOANS_SECURITY: 'loans_security',
  FB_LOANS_SECURITY_ARCHIVED: 'loans_security_archived',
  FB_LOANS_FOLLOW_UPS: 'loans_follow_ups',
  FB_LOANS_FOLLOW_UPS_REMOVED: 'loans_follow_ups_removed',
  FB_LOANS_TYPES: 'loans_types', // new, active, overdue, outsourced, written_off

  FB_DISBURSEMENTS: 'disbursements',
  FB_FOLLOW_UPS: 'follow_ups',
  FB_PAYMENTS: 'payments',

  FB_BANK_BALANCE: 'bank_balance',

  FB_MANUALLY_SENT_SMS: 'manually_sent_sms',
  FB_TICKET_REPLY_SENT_SMS: 'ticket_reply_sms',

  FB_SETTINGS: 'settings',
  FB_SETTINGS_GENERAL: 'general_settings',
  FB_SETTINGS_CLIENTS: 'clients_settings',
  FB_SETTINGS_LOANS: 'loans_settings',
  FB_SETTINGS_ACCOUNTING: 'accounting_settings',
  FB_SETTINGS_ACCOUNTING_SALES: 'sales_settings',
  FB_SETTINGS_ACCOUNTING_TAXES: 'taxes',

  FB_HISTORY: 'history',
  FB_COUNTERS: 'counters',
  FB_SUB_ITEMS: 'items',
  FB_SMS_BOX_HISTORY: 'sms_inbox_history',
  FB_EXCESS_PAID_AMOUNTS: 'excess_paid_amounts',
  FB_SMS_LOGS: 'sms_logs',

  FB_SUPPORT_CHATS: 'support_chats',
  FB_SUPPORT_NOTICES: 'support_notices',
  FB_SUPPORT_TICKETS: 'support_tickets',
  FB_TICKETS_COMMENTS: 'comments',

  FB_MESSAGES: 'messages',
  FB_MESSAGES_REMOVED: 'messages_removed',
  FB_MESSAGES_HISTORY: 'messages_history',

  FB_ASSETS: 'assets',
  FB_GUARANTORS: 'guarantors',
  FB_BUSINESSES: 'businesses',

  FB_FINANCIAL_ACCOUNTS: 'financial_accounts',
  // FB_ACCOUNTS: 'accounts',
  FB_ACCOUNTING_JOURNALS: 'financial_journals',
  FB_IMPORTED_ACCOUNTING_JOURNALS: 'imported_journals',
  // FB_JOURNALS: 'journals',
  FB_ACCOUNT_ENTRIES: 'journal_entries',
  FB_JOURNAL_ENTRIES: 'financial_journals_entries',
  FB_AUTOPOSTING_ACTIONS: 'autoposting_journal_actions',
  FB_INVOICES: 'invoices',
  FB_CREDIT_NOTES: 'credit_notes',
  FB_ACCOUNTING_AUDIT_LOGS: 'accounting_audit_logs',
  FB_ONBOARDING_CHART_OF_ACCOUNTS: 'onboarding_chart_of_accounts',

  // Account types
  FB_ASSET: 'asset',
  FB_EQUITY: 'equity',
  FB_LIABILITIES: 'liabilities',
  FB_REVENUE: 'revenue',
  FB_EXPENSES: 'expenses',

  DEFAULT_BULK: '335683',

  TICKECT_DUE_DAYS: 1,

  DEFAULT_OWNER: 'ENEZA',
  DEFAULT_CURRENCY: 'KES',
  DEFAULT_LOAN_RATE: 0.19,
  DEFAULT_LOAN_LIMIT: 100,
  DEFAULT_LOAN_DURATION: 2, // 2 months
  DEFAULT_SMS_SENDER_ID: 'ENEZA',

  LOAN_PROGRESSION_AMOUNT: 10,
  LOAN_PROGRESSION_CAP: 100,
  LOAN_1WEEK_RATE: 0.15,
  LOAN_2WEEKS_RATE: 0.17,
  // LOAN_3WEEKS_RATE: 0.19,

  FUNCTIONS_URL: 'https://us-central1-eneza-loans-dev.cloudfunctions.net',
  GOOGLE_MAP_KEY: 'AIzaSyB9Y9ZAeJAW8k-kRRaz_N8nWEBxskOggMI',
};
