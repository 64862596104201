import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { constants } from 'src/app/shared/constants';
import { HotToastService } from '@ngneat/hot-toast';
import { AdminUser } from '../models/admin.model';
import { Ticket } from '../models/ticket.model';
import { ActivityLogService } from './activity.log.service';

@Injectable()
export class TicketsService {

    public ticketCategories: Array<any> = [
        { name: 'support_request', value: 'Customer Support Request' },
        { name: 'collection_request', value: 'Debt Collection Request' },
        { name: 'failed_disbusment', value: 'Failed Disbusment' },
        { name: 'loans_limit', value: 'Loan Limit Error' },
        { name: 'review', value: 'Playstore Review' },
        { name: 'app_bug', value: 'Application Bugs' },
        { name: 'issues', value: 'Issues' },
        { name: 'twitter', value: 'Twitter DM' },
        { name: 'supportemail', value: 'Support Email' },
        { name: 'incompatible_device', value: 'Incompatible Device' },
        { name: 'support', value: 'Support' },
        { name: 'Loans', value: 'Loans' },
        { name: 'Dchums', value: 'Dchums' },
        { name: 'Obuy', value: 'Obuy' }
    ];

    constructor(
        private afs: AngularFirestore,
        private toast: HotToastService,
        private activityLogService: ActivityLogService
    ) { }

    assignToAdmin = async (ticketId: string, adminRoles: AdminUser) => {
        await this.afs.doc(`${constants.FB_SUPPORT_TICKETS}/${ticketId}`).update({
            assignedAdmin: {
                uid: adminRoles.uid,
                name: adminRoles.name,
                email: adminRoles.email,
                assignedDate: firebase.firestore.FieldValue.serverTimestamp()
            },
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }

    fromAdmin = async (ticketId: string, adminUser: AdminUser) => {
        await this.afs.doc(`${constants.FB_SUPPORT_TICKETS}/${ticketId}`).update({
            fromAdmin: {
                uid: adminUser.uid,
                name: adminUser.name,
                email: adminUser.email,
                assignedDate: firebase.firestore.FieldValue.serverTimestamp()
            },
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }

    closeTickets(ticketsUids: any) {
        ticketsUids.forEach((selected) => {
            const ticket = { status: 'closed' };
            return this.afs.collection(constants.FB_SUPPORT_TICKETS).doc(selected.uid)
                .update(ticket)
                .then(() => this.toast.success('Ticket(s) closed successfully...'))
                .catch(err => {
                    console.log(`ticket update failed ${err}`);
                });
        });
    }

    doCloseTicket = async (adminUser: firebase.User, docId: string) => {
        await this.afs.collection(constants.FB_SUPPORT_TICKETS)
            .doc(docId)
            .update({
                status: 'closed',
                closedBy: adminUser.uid,
                closedByEmail: adminUser.email,
                closedAt: firebase.firestore.FieldValue.serverTimestamp(),
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            });
        await this.activityLogService.addActivityLog(constants.FB_SUPPORT_TICKETS, 'update', docId);
    }

    doReopenTicket = async (adminUser: firebase.User, docId: string) => {
        return this.afs.collection(constants.FB_SUPPORT_TICKETS)
            .doc(docId)
            .update({
                status: 'reOpened',
                reOpenedBy: adminUser.uid,
                reOpenedByEmail: adminUser.email,
                reOpenedAt: firebase.firestore.FieldValue.serverTimestamp(),
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            });
    }

    commentAndCloseTicket = async (ticketId: string, adminRoles: AdminUser) => {
        await this.afs.doc(`${constants.FB_SUPPORT_TICKETS}/${ticketId}`).update({
            status: 'closed',
            closedBy: adminRoles.uid,
            closedByName: adminRoles.name,
            closedByEmail: adminRoles.email,
            closedAt: firebase.firestore.FieldValue.serverTimestamp(),
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            customerRepresentative: {
                name: adminRoles.name,
                uid: adminRoles.uid,
            }
        });
        await this.activityLogService.addActivityLog(constants.FB_SUPPORT_TICKETS, 'update', ticketId);
        await this.assignToAdmin(ticketId, adminRoles);
    }

    createNewTicket = async (ticket: Ticket) => {
        ticket.totalCount = 0;
        return this.afs.collection(constants.FB_SUPPORT_TICKETS).add(Object.assign({}, ticket)).then((doc) => {
            this.toast.success('Ticket successfully created .');
            console.log(JSON.stringify(doc));
            return JSON.stringify(doc);
        }).catch((error) => {
            console.error('CREATE NEW TICKET ERROR:::', error);
            this.toast.success('Ticket creation failed created .');
            return null;
        });
    }

    getTicketCommentsData(ticketId: string) {
        return this.afs
            .collection(constants.FB_SUPPORT_TICKETS)
            .doc(ticketId)
            .collection(constants.FB_TICKETS_COMMENTS, ref => {
                return ref.orderBy('dateCreated', 'asc');
            });
    }

    createNewComment(comment: any, type: string) {
        const id = this.afs.createId();

        comment.commentType = 'agentReply';
        comment.lastModified = '';
        comment.uid = id;

        return this.afs.doc(`${constants.FB_SUPPORT_TICKETS}/${comment.ticketId}`).collection(constants.FB_TICKETS_COMMENTS).doc(id).set(comment).then(() => {
            this.toast.success('Comment added .');
        }).catch((error) => {
            this.toast.error('An error occured additting this ticket comment.');
            console.log(error);
        });
        // return this.afs.collection(constants.FB_TICKETS_COMMENTS).doc().collection(constants.FB_TICKETS_COMMENTS).doc(id).set(comment);

    }

    updateTicket = async (doc: string, ticket: Ticket) => {
        return this.afs.collection(constants.FB_SUPPORT_TICKETS).doc(doc).set(ticket)
            .then(() => this.toast.success('Ticket updated .'))
            .catch(() => this.toast.error('An error occured updating this ticket.'));
    }

    getTicketById(ticketId: string): Observable<any> {
        return this.afs
            .collection(constants.FB_SUPPORT_TICKETS)
            .doc(ticketId)
            .valueChanges();
    }

    getTicketsCount() {
        return this.afs
            .collection(constants.FB_COUNTERS)
            .doc(constants.FB_SUPPORT_TICKETS)
            .valueChanges();
    }

    getOtherTickets(clientId: string, ticketId: string, max: number) {
        return this.afs.collection(constants.FB_SUPPORT_TICKETS, ref => {
            return ref
                .where('clientId', '==', clientId)
                .orderBy('dateCreated', 'desc')
                .limit(max);
        }).snapshotChanges().pipe(
            map(changes => {
                return changes.filter(newRow => {
                    let row: any;
                    if (ticketId !== newRow.payload.doc.id) row = newRow;
                    return row;
                }).map(a => {
                    const data = a.payload.doc.data() as Ticket;
                    const id = a.payload.doc.id;
                    const docId = id;
                    return { id, docId, ...data };
                });
            })
        );
    }

    getCategory(value: string): string {
        let category = 'General category';
        if (value === 'failed_disbusment') {
            category = 'Failed Disbusment';
        } else if (value === 'support_request') {
            category = 'Customer Support Request';
        } else if (value === 'loans_limit') {
            category = 'Loans Limit';
        } else if (value === 'loans_default') {
            category = 'Loans Default';
        } else if (value === 'app_bug') {
            category = 'Application bug';
        } else if (value === 'incompatible_device') {
            category = 'Device compatibility';
        } else if (value === 'review') {
            category = 'Playstore Review';
        } else if (value === 'twitter') {
            category = 'Twitter DM';
        } else if (value === 'loans-limit') {
            category = 'Loan Limit';
        } else if (value === 'collection_request') {
            category = 'Debt Collection Request';
        } else if (value === 'supportemail') {
            category = 'Support Email';
        } else if (value === 'Loans') {
            category = 'Loans';
        } else if (value === 'Dchums') {
            category = 'Dchums';
        } else if (value === 'Obuy') {
            category = 'Obuy';
        }

        return category;
    }

}
