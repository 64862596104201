export class AdminUser {

  public uid: string;
  public name: string;
  public email: string;
  public roles: string[] = [];
  public isAdmin = false;
  public isSuspended = false;
  public lastUpdated?: any;

  constructor(userId: string, email: string, admin: any) {
    this.uid = userId;
    this.name = admin.name || ' ';
    this.email = admin.email || email;
    this.roles = admin.roles || [];
    this.isAdmin = admin.isAdmin || [];
  }

}

export class AdminDevice {

  public guid: number;
  public screenWidth: number;
  public screenHeight: number;
  public screenPixelDepth: number;
  public userAgent: string;

  constructor(device) {
    this.guid = device.guid;
    this.screenWidth = device.screenWidth;
    this.screenHeight = device.screenHeight;
    this.screenPixelDepth = device.screenPixelDepth;
    this.userAgent = device.userAgent;
  }

  isEquals(other): boolean {
    return ((this.guid === other.guid) &&
      (this.screenWidth === other.screenWidth) &&
      (this.screenHeight === other.screenHeight) &&
      (this.screenPixelDepth === other.screenPixelDepth) &&
      (this.userAgent === other.userAgent));
  }
}
