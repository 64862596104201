export class ActivityLog {
  docId?: string;
  userId: string;
  identifier?: string; // email or phone number
  collection: string;
  eventType: ActivityLogEventType;
  timestamp: any;
}

export type ActivityLogEventType = 'create' | 'update' | 'delete';
