export class MpesaReceivedTransaction {
  docId?: string;
  type: string;
  msisdn: string;
  transId: string;
  lastname: string;
  transTime: string;
  firstname: string;
  transType: string;
  middlename: string;
  clientCode: string;
  transAmount: string;
  billRefNumber: string;
  invoiceNumber: string;
  orgAccBalance: string;
  businessShortCode: string;
  thirdPartyTransID: string;
}
