export class UserNotification {
  public docId: string;
  public notificationType: string;
  public notificationNo: string;

  public status = 'active';
  public sendTo: string;
  public message: string;
  public loanAge = 2;
  public crbClass: string;
  public partialPayment = false;

  // Days to send.
  public sunday = true;
  public monday = true;
  public tuesday = true;
  public wednesday = true;
  public thursday = true;
  public friday = true;
  public saturday = true;

  public createdBy: string;
  public updatedBy: string;
  public createdByEmail: string;
  public updatedByEmail: string;
  public lastViewBy: string;
  public lastViewByEmail: string;
  public deletedBy: string;
  public deletedByEmail: string;

  public sendTime: any;
  public dateCreated: any;
  public dateUpdated: any;
  public dateViewed: any;
  public startDate: any;
  public endDate: any;
  public dateLastSent: any;
  public dateDeleted: any;

  public isDeleted: boolean;
}
