import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Injectable()
export class UtilsService {
  constructor(private datePipe: DatePipe) { }

  getAsObject(customObj: any): object {
    const result = {};
    Object.keys(customObj).map(key => {
      result[key] = customObj[key];
      return key;
    });
    return result;
  }

  jsonToCsv(data: any, headers: any, includeHeaders: boolean = true, changeCase: boolean = true): any {
    const replacer = (_key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    let csv = data.map((row: any) => headers.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

    if (includeHeaders) {
      if (changeCase) {
        csv.unshift(headers.map(fieldName => this.toNormalCase(fieldName)).join(','));
      } else {
        csv.unshift(headers.join(','));
      }
    }
    csv = csv.join('\r\n');
    return csv;
  }

  borrowingReason(reason: number) {
    if (reason === 1) {
      return 'Business Start Up';
    } else if (reason === 2) {
      return 'Business Expansion';
    } else {
      return 'Other';
    }
  }

  loanJsonToCsv(data: any, headers: any, repaid = false): any {
    const replacer = (_key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const datesArray = ['dateRequested', 'datePaid', 'lastPaymentDate', 'dateApproved', 'dateDisbursed', 'instalmentDueDate', 'dueDate', 'dateRepaid', 'dateCancelled'];
    const numbersArray = ['amountRequested', 'amountOverdue', 'expectedPaymentToDate', 'amountPaid', 'amountToRepay', 'principalPaid', 'interestPaid', 'amountToRepay', 'principalToRepay', 'interestToRepay', 'rolloverPaid', 'processingFeePaid'];
    const interestArray = ['interest'];
    const reasonArray = ['reason'];

    let csv = data.map((row: any) => {

      row.phoneNumber = row.client.phoneNumber;
      row.amountRequested = row.amountRequested;
      row.dateDisbursed = row.disbursedAt;
      row.dateRequested = row.requestedAt;
      row.instalmentDueDate = row.nextDueAt;
      row.lastPaymentDate = row.lastPaymentAt;
      row.dateCancelled = row.cancelledAt;
      row.dueDate = row.dueAt;
      row.names = row.client.firstName + ' ' + row.client.lastName;
      row.initialAmountToRepay = Math.ceil((row?.initAmounts?.amountToRepay));
      row.datePaid = row.repaidAt;

      row.age = row?.client?.age ?? '';
      row.score = row?.client?.score ?? '';
      row.sex = row?.client?.sex ?? '';

      if (repaid) {
        row.activeLoanId = row.loanId;
      }

      row.loanId = row.docId;

      let firstMonth = moment(row.requestedAt.toDate()).startOf('month');
      if (row.effectiveDate) {
        firstMonth = moment(row.effectiveAt.toDate()).startOf('month');
      }

      const thisMonth = moment(new Date()).subtract(1).endOf('month');
      row.pastMonths = Math.ceil(+thisMonth.diff(firstMonth, 'weeks', false));
      row.expectedPaymentToDate = (row.monthlyPayment * row.pastMonths);
      row.amountOverdue = row.expectedPaymentToDate - row.amountPaid;

      const contains = headers.includes('amountOverdue');
      if (contains) {
        row.expectedMonthlyPayment = Math.ceil(row.unpaidToRepay / row.loanDuration);
      }

      row.weeklyPayment = Math.ceil(row.monthlyPayment);

      return headers.map(fieldName => {
        if (datesArray.indexOf(fieldName) > -1) {
          return JSON.stringify((row[fieldName]) ? (this.datePipe.transform(row[fieldName].toDate(), 'dd-MM-yyyy')) : '', replacer);
        } else if (numbersArray.indexOf(fieldName) > -1) {
          return JSON.stringify((row[fieldName] ?? 0).toFixed(2), replacer);
        } else if (interestArray.indexOf(fieldName) > -1) {
          return JSON.stringify(row[fieldName] < 1 ? (row[fieldName] * 100).toFixed(2) : row[fieldName], replacer);
        } else if (reasonArray.indexOf(fieldName) > -1) {
          return this.borrowingReason(row[fieldName]);
        } else { // all other fields
          return JSON.stringify(row[fieldName], replacer);
        }
      }).join(',');
    });
    csv.unshift(headers.map(fieldName => this.toNormalCase(fieldName)).join(','));
    csv = csv.join('\r\n');
    return csv;
  }

  loanDataToCsv(data, headers): any {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const datesArray = ['dateRequested', 'effectiveDate', 'dateApproved', 'dateDisbursed', 'chosenDueDate', 'dueDate', 'dateRepaid', 'dateCancelled'];
    const numbersArray = ['amountRequested', 'amountToRepay', 'initAmountToRepay', 'interestToRepay', 'monthlyPayment', 'amountPaid', 'principalPaid', 'interestPaid', 'rolloverPaid'];

    let csv = data.map(row => {
      return headers.map(fieldName => {
        if (datesArray.indexOf(fieldName) > -1) {
          return JSON.stringify(row[fieldName].toDate(), replacer);
        } else if (numbersArray.indexOf(fieldName) > -1) {
          return JSON.stringify(Math.round(row[fieldName]), replacer);
        } else { // all other fields
          return JSON.stringify(row[fieldName], replacer);
        }
      }).join(',');
    });
    csv.unshift(headers.map(fieldName => this.toNormalCase(fieldName)).join(','));
    csv = csv.join('\r\n');
    return csv;
  }

  toNormalCase(fieldName: string): string {
    return (fieldName) ? fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()) : fieldName;
  }

  toDateString(date: Date, fmtString: string = 'dd/MM/yyyy') {
    return this.datePipe.transform(date, fmtString);
  }

  toHeadersCase(headers: any): string {
    return headers.map(fieldName => this.toNormalCase(fieldName)).join(',');
  }

  sanitizePhoneNumber = (phoneNumber: string, prefix: string) => {
    let newPhoneNumber: string = phoneNumber;
    if (phoneNumber && (phoneNumber.trim() !== '')) {
      if (phoneNumber.startsWith('07')) {
        // Hack to convert phoneNumber to e164 format
        newPhoneNumber = phoneNumber.replace(/^.{1}/g, prefix).trim();
      }
      if (phoneNumber.startsWith('7')) {
        // Hack to convert phoneNumber to e164 format
        newPhoneNumber = prefix + phoneNumber.trim();
      }
      newPhoneNumber = newPhoneNumber.trim().replace(/\+/g, '').replace(/\s/g, '');
    }
    return newPhoneNumber;
  }
}
