import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public options = {
    timeOut: 5000,
    preventDuplicates: true,
    position: ['right', 'top']
  };

  constructor(private title: Title) {
    this.title.setTitle('Eneza Loans Admin');
  }
}
