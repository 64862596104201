import { constants } from 'src/app/shared/constants';
import * as enums from '../enums';

export class LoanSecurity {

  public docId: string;
  public loanId: string;
  public clientId: string;
  public securityId: string;
  public securityType: string; // assets, guarantors
  public securityTitle: string;
  public amountGuaranteed: number;
  public initAmountGuaranteed: number;
  public repaymentRatio = 1; // ranges from 0 - 1 based on amountGuaranteed vs amountRequested
  public currency: string = constants.DEFAULT_CURRENCY; // default KES
  public clientType: string = enums.LoanClient.PERSON;
  public status = 'active';

  public dateCreated?: any;
  public lastUpdated?: any;

}

export class Asset {

  public docId: string;
  public title: string;
  public clientId: string;
  public description: string;
  public downloadName: string;
  public downloadURL: string;
  public type: string = enums.AssetType.LOG_BOOK; // logbook, titledeed
  public clientType: string = enums.LoanClient.PERSON; // person, business
  public currency: string = constants.DEFAULT_CURRENCY; // default KES
  public status = 'active';

  public valuation: number;
  public discountRate: number;
  public discountedValue: number;
  public committedValue = 0;
  public remainingValue = 0;
  public amountGuaranteed = 0;

  public dateCreated: any;
  public lastUpdated: any;

}

export class Guarantor {

  public docId: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public phoneNumber: string;
  public description: string;
  public currency = 'KES'; // default KES
  public status = 'active';

  public valuation: number;
  public discountRate: number;
  public discountedValue: number;
  public committedValue = 0;
  public remainingValue: number;
  public amountGuaranteed?: number;

  public dateCreated: any;
  public lastUpdated: any;

}

export class Disbursement {

  public docId: string;
  public api?: string;
  public type: string;
  public status: string;
  public amount: number;
  public dateCreated: any;
  public dateDisbursed?: any;

}
