import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { constants } from 'src/app/shared/constants';

@Injectable()
export class NotifyService {

    constructor(
        private afs: AngularFirestore
    ) { }

    async sendSMS(smsDetails: any): Promise<void> {
        const manuallysentSMSCollection = this.afs.collection(constants.FB_MANUALLY_SENT_SMS);
        await manuallysentSMSCollection.add(smsDetails);
    }
}
