
import { Injectable } from '@angular/core';
import { constants } from 'src/app/shared/constants';
import firebase from 'firebase/compat/app';
import * as models from '../models';

@Injectable()
export class SettingsService {

    constructor() { }

    // GENERAL SETTINGS
    getGeneralSettings = async () => {
        return firebase.firestore()
            .collection(constants.FB_SETTINGS)
            .doc(constants.FB_SETTINGS_GENERAL)
            .get();
    }

    getGeneralSettingsObj = async () => {
        const snapshot = await this.getGeneralSettings();
        const settings = snapshot.data() as models.GeneralSettings;
        settings.docId = snapshot.id;
        return settings;
    }

    // LOANS SETTINGS
    getLoansSettings = async () => {
        return firebase.firestore()
            .collection(constants.FB_SETTINGS)
            .doc(constants.FB_SETTINGS_LOANS)
            .get();
    }

    getLoansSettingsObj = async () => {
        const snapshot = await this.getLoansSettings();
        const settings = snapshot.data() as models.LoansSettings;
        settings.docId = snapshot.id;

        return settings;
    }

}
