import { Injectable } from '@angular/core';
import { constants } from 'src/app/shared/constants';
import { Loan } from '../models';
import firebase from 'firebase/compat/app';

@Injectable()
export class TransactionService {
  constructor() {}

  getTransactionById = async (transactionId: string) => {
    const snapshot = await firebase
      .firestore()
      .collection(constants.FB_MPESA_MANUAL_TRANSACTIONS)
      .doc(transactionId)
      .get();
    return snapshot.data();
  }

  getTransactionByCode = async (code: string, direction?: string) => {
    direction = direction || 'incoming'; // default to 'incoming' transactions direction
    const snapshot = await firebase
      .firestore()
      .collection(constants.FB_MPESA_MANUAL_TRANSACTIONS)
      .where('direction', '==', direction)
      .where('receiptNo', '==', code)
      .get();
    let transaction: any = null;
    if (!snapshot.empty) {
      transaction = snapshot.docs[0].data();
      transaction.docId = snapshot.docs[0].id;
    }
    return transaction;
  }

  getSentTransaction = async (loan: Loan) => {
    const snapshot = await firebase
      .firestore()
      .collection(constants.FB_MPESA_MANUAL_TRANSACTIONS)
      .where('direction', '==', 'outgoing')
      .where('amount', '==', loan.amountRequested)
      .where('phoneNumber', '==', loan.client.phoneNumber)
      .where('completionTime', '>=', loan.requestedAt)
      .get();
    let transaction: any = null;
    if (!snapshot.empty) {
      transaction = snapshot.docs[0].data();
      transaction.docId = snapshot.docs[0].id;
    }
    return transaction;
  }

  getRepaidExists = async (code: string, amount: number) => {
    let isValidTransaction = false;
    const transaction: any = await this.getTransactionByCode(code);
    if (transaction && transaction.receiptNo === code) {
      transaction.remainingAmount =
        transaction.remainingAmount || transaction.amount;
      if (transaction.remainingAmount >= amount) {
        const remainingAmount: number = transaction.remainingAmount - amount;
        console.log(`getRepaidExists > remainingAmount:`, {
          before: transaction.remainingAmount,
          after: remainingAmount,
        });
        await this.updateRemainingAmount(transaction.docId, remainingAmount);
        isValidTransaction = true;
      } else {
        console.error(`Mpesa transaction NOT FOUND:`, {
          remainingAmount: transaction.remainingAmount,
        });
      }
    }
    console.log(`getRepaidExists > hasMatch:`, isValidTransaction);

    return isValidTransaction;
  }

  getSentExists = async (loan: Loan) => {
    const transaction: any = await this.getSentTransaction(loan);
    console.log(
      `getSentExists > cond: ${transaction !== null}, transaction:`,
      transaction
    );
    return transaction !== null;
  }

  updateRemainingAmount = async (docId: string, remainingAmount: number) => {
    await firebase
      .firestore()
      .collection(constants.FB_MPESA_MANUAL_TRANSACTIONS)
      .doc(docId)
      .update({
        remainingAmount,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }
}
