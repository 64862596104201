import { constants } from '../constants';
import * as enums from '../enums';
import {
  BankDetails,
  ClientDetails,
  DebtCollector,
  EarlyRepayment,
  FollowUps,
  Guarantor,
  InitialAmounts,
  Meta,
  Restructured,
  TopUps,
  Transaction,
} from './common.models';

export class Loan {
  static currentAppVersion = '1.0.3 (15)';
  static currentWebSource = 'web';

  docId: string;
  client: ClientDetails;
  type: string;
  owner: string;
  interest: number;
  duration: number;
  durationIn?: string;
  currency: string = constants.DEFAULT_CURRENCY;
  status: string = enums.LoanStatus.PENDING;
  previousStatus?: string = enums.LoanStatus.PENDING;
  previousLoanAge = 0;
  actionType?: string;
  location: any;
  originalLoanId: string;
  deviceInfo: any;
  loanDueStatus: any;
  source?: string;
  purpose: string;
  otherPurpose: string;
  networkInfo: any;
  deviceUUID: string;
  amountRequested: number;
  amountToDisburse: number;
  amountToRepay: number;
  interestToRepay: number;
  principalToRepay: number;
  initAmountToRepay: number;
  rollOverAmountToRepay: number;
  rollOverAmountPaid: number;
  amountPaid?: number;
  processingFeePaid?: number;
  principalPaid?: number;
  interestPaid?: number;
  guarantor?: Guarantor;
  amountGuaranteed?: number;
  monthlyPayment?: number;
  currentInstalment?: number;
  disburseAttempts: number;
  transactionAmount?: number;
  transactionCode?: string;
  transactionSource?: string;
  followUps?: FollowUps = new FollowUps();
  hasPendingAction = false;
  hasAcceptedTerms = false;
  isRestructuring = false;
  restructured?: Restructured = new Restructured();
  isNoticeViewed = false;
  retryDisburse = false;
  topUps?: TopUps;
  note?: string;
  pendingAction?: any;
  lastTransaction?: Transaction;
  transaction: Transaction[];
  debtCollector?: DebtCollector;
  sendTo: string = enums.LoanDisburseOption.MOBILE_MONEY;
  appVersion: string;
  fcmToken: string;
  rollOverStatus?: string;
  bankDetails?: BankDetails = new BankDetails();
  chequeNumber?: string;
  paymentType?: string; // instalment, full, partial
  createdAt: any;
  monthlyPaymentsTotal?: number;
  requestedAt: any;
  approvedAt: any;
  disbursedAt: any;
  dueAt?: any;
  prevDueAt?: any;
  repaidAt: any;
  chosenDueAt?: any;
  canceledAt: any;
  declinedAt: any;
  firstPaymentAt: any;
  commitmentAt: any;
  effectiveAt: any;
  initialAmounts: InitialAmounts = new InitialAmounts();
  lastRetryAt: any;
  processedAt: any;
  nextDueAt: any;
  meta?: Meta;
  earlyRepayment?: EarlyRepayment;
}
