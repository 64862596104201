export class LoanExciseDuty {
  public docId: string;
  public loanId: string;
  public repaidLoanId: string;
  public clientId: string;
  public olaId: string;
  public type: string; // processing, renewal
  public category: string; // normal, special
  public clientType: string;
  public phoneNumber: string;
  public owner: string;

  public currency: string;
  public amountPaid: number;
  public amountToRepay: number;

  public doneByEmail: string;
  public doneBy: string;

  public dateCreated: any;
  public lastUpdated: any;
}
