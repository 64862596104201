import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { constants } from 'src/app/shared/constants';
import { FollowUp, Loan } from 'src/app/shared/models';
import { UtilsService } from './utils.service';
import firebase from 'firebase/compat/app';
import * as moment from 'moment';

@Injectable()
export class FollowUpService {

    constructor(
        private afs: AngularFirestore,
        private utilsService: UtilsService
    ) { }

    doSaveFollowUp = async (adminUser: firebase.User, loan: Loan, followUp: FollowUp) => {
        const loanDocument = this.afs.collection(constants.FB_LOANS).doc(loan.docId);
        const followUpsCollection = loanDocument.collection(constants.FB_FOLLOW_UPS);
        followUp.loanId = loan.docId;
        followUp.clientId = loan.client.clientId;
        followUp.dueDate = loan.dueAt;
        followUp.amount = loan.amountToRepay;
        followUp.doneBy = adminUser.uid;
        followUp.doneByEmail = adminUser.email;
        followUp.date = firebase.firestore.FieldValue.serverTimestamp();

        await followUpsCollection.add(this.utilsService.getAsObject(followUp));
    }

    doDeleteFollowUps = async (adminUser: firebase.User, loanId: string, followUp: any) => {
        const loanDocument = this.afs.collection(constants.FB_LOANS).doc(loanId);
        followUp.deletedBy = adminUser.uid;
        followUp.deletedByEmail = adminUser.email;
        followUp.dateDeleted = firebase.firestore.FieldValue.serverTimestamp();
        await loanDocument.collection(constants.FB_LOANS_FOLLOW_UPS_REMOVED).add(followUp);
        await loanDocument.collection(constants.FB_FOLLOW_UPS).doc(followUp.docId).delete();
    }

    doUpdateLoan = async (loanId: string, followUpCalls, followUpEmails, lineStatus, commitmentDate) => {
        const loansCollection = this.afs.collection(constants.FB_LOANS);
        const changes: any = {
            isNoticeViewed: false,
            isNewlyOverdue: false,
            lineStatus,
            followUpCalls,
            followUpEmails,
        };
        if (commitmentDate) changes.commitmentDate = moment(commitmentDate).toDate();
        await loansCollection.doc(loanId).update(changes);
    }

}
