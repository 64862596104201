import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  QuerySnapshot,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AdminUser } from '../models/admin.model';
import { map } from 'rxjs/operators';
import { constants } from '../constants';
import { AdminAuthRoles } from '../enums/auth.roles.enum';
import { AuthService } from './auth.service';

@Injectable()
export class AdminService {
  constructor(
    private afs: AngularFirestore,
    private authService: AuthService
  ) {}

  getByDocId(docId: string): Observable<firebase.firestore.DocumentSnapshot> {
    const adminsCollection = this.afs.collection(constants.FB_ADMINS);
    return adminsCollection.doc(docId).get();
  }

  getByDocId2 = async (docId: string): Promise<AdminUser> => {
    const snaphot = await this.afs
      .collection(constants.FB_ADMINS)
      .doc(docId)
      .ref.get();
    const admin = snaphot?.data() as AdminUser;
    if (admin) {
      admin.uid = snaphot?.id;
    }
    return admin;
  }

  getByRole(role: string): Promise<QuerySnapshot<any>> {
    return this.afs
      .collection(constants.FB_ADMINS)
      .ref.where('roles', 'array-contains', role)
      .get();
  }

  getAdminUserRoles(docId: string, email: string): Observable<AdminUser> {
    const adminsCollection = this.afs.collection(constants.FB_ADMINS);
    return adminsCollection
      .doc(docId)
      .valueChanges()
      .pipe(
        map((doc) => {
          return doc // document exists
            ? new AdminUser(docId, email, doc)
            : null;
        })
      );
  }

  getAdminUser(docId: string, email: string): Observable<AdminUser> {
    const adminsCollection = this.afs.collection(constants.FB_ADMINS);
    return adminsCollection
      .doc(docId)
      .valueChanges()
      .pipe(
        map((doc) => {
          return doc // document exists
            ? new AdminUser(docId, email, doc)
            : null;
        })
      );
  }

  getAdminAuthRoles = async () => {
    if (!this.authService.isLoggedIn()) {
      return {
        isAdmin: false,
        roles: [],
        email: '',
        uid: '',
        name: '',
      } as AdminUser;
    }

    const currentUser = firebase.auth().currentUser;
    const token = await currentUser.getIdTokenResult();
    return {
      uid: currentUser.uid,
      email: currentUser.email,
      name: currentUser.displayName,
      roles: token.claims.roles || [],
      isAdmin: token.claims.isAdmin || false,
    } as AdminUser;
  }

  // HIGH LEVEL AUTH
  getHasViewAllRole(adminRoles: AdminUser): boolean {
    return (
      adminRoles.roles.includes(AdminAuthRoles.ADMIN) ||
      adminRoles.roles.includes(AdminAuthRoles.ACCOUNTANT) ||
      adminRoles.roles.includes(AdminAuthRoles.CUSTOMER_CARE)
    );
  }

  getCanViewAll(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanManageAdminRoles(adminRoles: AdminUser): boolean {
    return adminRoles.roles.includes(AdminAuthRoles.ADMIN);
  }

  getCanExportAllData(adminRoles: AdminUser): boolean {
    return adminRoles.roles.includes(AdminAuthRoles.ADMIN);
  }

  getCanConfirmLoanActions(adminRoles: AdminUser): boolean {
    return (
      adminRoles.roles.includes(AdminAuthRoles.ADMIN) ||
      adminRoles.roles.includes(AdminAuthRoles.ACCOUNTANT)
    );
  }

  // DASHBOARD METRICS AUTH
  getCanViewSuperDasboard(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanViewDasboard(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanViewSupportDashboard(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanViewBankDashboard(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanViewRepaidLoansDasboard(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  // CLIENTS AUTH
  getCanViewUsers(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanViewUsersMpesa(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanViewUserProfile(adminRoles: AdminUser): boolean {
    return this.getCanViewAll(adminRoles);
  }

  getCanManageUsers(adminRoles: AdminUser): boolean {
    return (
      adminRoles.roles.includes(AdminAuthRoles.ADMIN) ||
      adminRoles.roles.includes(AdminAuthRoles.CUSTOMER_CARE)
    );
  }

  getCanEditUserLimits(adminRoles: AdminUser): boolean {
    return this.getCanManageUsers(adminRoles);
  }

  getCanConfirmUserLimits(adminRoles: AdminUser): boolean {
    return this.getCanManageUsers(adminRoles);
  }

  getCanDisqualifyUsers(adminRoles: AdminUser): boolean {
    return this.getCanManageUsers(adminRoles);
  }

  getCanSetDailyRepayments(adminRoles: AdminUser): boolean {
    return (
      adminRoles.roles.includes(AdminAuthRoles.ADMIN) ||
      adminRoles.roles.includes(AdminAuthRoles.ACCOUNTANT)
    );
  }

  getCanSearchMpesaNames(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanAppraiseUsers(adminRoles: AdminUser): boolean {
    return adminRoles.roles.includes(AdminAuthRoles.ADMIN);
  }

  getCanViewUsersAssets(adminRoles: AdminUser): boolean {
    return this.getCanViewAll(adminRoles);
  }

  getCanViewUsersSmsLogs(adminRoles: AdminUser): boolean {
    return this.getCanViewAll(adminRoles);
  }

  getCanDeleteUsers(adminRoles: AdminUser): boolean {
    return this.getCanManageUsers(adminRoles);
  }

  getCanEditEmail(adminRoles: AdminUser): boolean {
    return this.getCanManageUsers(adminRoles);
  }

  getCanEditPhoneNumber(adminRoles: AdminUser): boolean {
    return this.getCanManageUsers(adminRoles);
  }

  // LOANS AUTH
  getCanViewLoans(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanManageLoans(adminRoles: AdminUser): boolean {
    return (
      adminRoles.roles.includes(AdminAuthRoles.ADMIN) ||
      adminRoles.roles.includes(AdminAuthRoles.ACCOUNTANT)
    );
  }

  getCanCancelLoans(adminRoles: AdminUser): boolean {
    return this.getCanManageLoans(adminRoles);
  }

  getCanMarkLoanAsRenewed(adminRoles: AdminUser): boolean {
    return (
      this.getCanManageUsers(adminRoles) ||
      adminRoles.roles.includes(AdminAuthRoles.ACCOUNTANT)
    );
  }

  getCanCreateLoans(adminRoles: AdminUser): boolean {
    return this.getCanManageLoans(adminRoles);
  }

  getCanQuickRestructureLoans(adminRoles: AdminUser): boolean {
    return this.getCanManageLoans(adminRoles);
  }

  getCanDisburseLoans(adminRoles: AdminUser): boolean {
    return this.getCanManageLoans(adminRoles);
  }

  // MISC AUTH
  getCanViewFinances(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanManageFinances(adminRoles: AdminUser): boolean {
    return (
      adminRoles.roles.includes(AdminAuthRoles.ADMIN) ||
      adminRoles.roles.includes(AdminAuthRoles.ACCOUNTANT)
    );
  }

  getCanFectchCRBData(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  // GUARANTORS AUTH
  getCanCreateGuarantors(adminRoles: AdminUser): boolean {
    return (
      adminRoles.roles.includes(AdminAuthRoles.ADMIN) ||
      adminRoles.roles.includes(AdminAuthRoles.CUSTOMER_CARE) ||
      adminRoles.roles.includes(AdminAuthRoles.ACCOUNTANT)
    );
  }

  // SUPPORT AUTH
  getCanViewSupport(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanCreateTickets(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanManageTickets(adminRoles: AdminUser): boolean {
    return (
      adminRoles.roles.includes(AdminAuthRoles.ADMIN) ||
      adminRoles.roles.includes(AdminAuthRoles.CUSTOMER_CARE)
    );
  }

  getCanCloseTicket(adminRoles: AdminUser): boolean {
    return (
      adminRoles.roles.includes(AdminAuthRoles.ADMIN) ||
      adminRoles.roles.includes(AdminAuthRoles.CUSTOMER_CARE)
    );
  }

  // USER NOTIFICATIONS
  getCanCreateUserNotifications(adminRoles: AdminUser): boolean {
    return this.getCanViewAll(adminRoles);
  }

  getCanViewUserNotificaions(adminRoles: AdminUser): boolean {
    return this.getCanViewAll(adminRoles);
  }

  getCanManageUserNotifications(adminRoles: AdminUser): boolean {
    return (
      adminRoles.roles.includes(AdminAuthRoles.ADMIN) ||
      adminRoles.roles.includes(AdminAuthRoles.CUSTOMER_CARE)
    );
  }

  getCanViewClientsReachedCap(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  // MESSAGES AUTH
  getCanViewMessages(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  // SYSTEM SETTINGS AUTH
  getCanCreateAdmin(adminRoles: AdminUser): boolean {
    return adminRoles.roles.includes(AdminAuthRoles.ADMIN);
  }

  getCanViewSystemSettings(adminRoles: AdminUser): boolean {
    return this.getHasViewAllRole(adminRoles);
  }

  getCanUpdatePriorityModel(adminRoles: AdminUser): boolean {
    return adminRoles.roles.includes(AdminAuthRoles.ADMIN);
  }

  getCanUpdateSystemFloat(adminRoles: AdminUser): boolean {
    return adminRoles.roles.includes(AdminAuthRoles.ADMIN);
  }

  async getFinanceCommittee(): Promise<AdminUser[]> {
    const snapshot = await this.getByRole(AdminAuthRoles.ADMIN);
    console.log('getFinanceCommittee > size:', snapshot.size);

    return snapshot.docs.map((doc) => {
      const admin = doc.data() as AdminUser;
      admin.uid = doc.id;
      return admin;
    });
  }
}
