import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { constants } from '../constants';
import { ActivityLogEventType, ActivityLog } from '../models/activity.log.model';

@Injectable()
export class ActivityLogService {
    constructor(
        private afs: AngularFirestore
    ) { }

    async addActivityLog(collection: string, eventType: ActivityLogEventType, docId: string): Promise<void> {
        const currentUser = firebase.auth().currentUser;

        const activityLog = new ActivityLog();
        activityLog.collection = collection;
        activityLog.eventType = eventType;
        activityLog.docId = docId;
        activityLog.userId = currentUser.uid;
        activityLog.identifier = currentUser.email || currentUser.phoneNumber || '';
        activityLog.timestamp = firebase.firestore.FieldValue.serverTimestamp();

        await this.afs.collection(constants.FB_ACTIVITY_LOGS).add({ ...activityLog });
    }
}
