export class Owner {

  public docId: string;
  public owner: string;
  public name: string; // The agent (enterpsise, business, etc) full name
  public paybill: string;

  public float = 0;
  public maxLoanAmount = 100;

  public dateCreated: string;
  public createdBy: string; // Current super admin uid
  public createdByEmail: string;

  public lastUpdated: any;
  public updatedBy: string; // Current super admin uid
  public updatedByEmail: string;

  // Additional history fields
  public isPartner = false;
  public isDeleted = false;
  public dateDeleted?: any;
  public deletedBy?: string;
  public deletedByEmail?: string;

  // API FIELDS
  public consumerKey?: string;
  public consumerSecret?: string;
  public reads = 0;

}
