import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs/operators';
import { constants } from 'src/app/shared/constants';
import { ActivityLogService } from './activity.log.service';
import { HistoryService } from './history.service';
import * as enums from 'src/app/shared/enums';
import * as models from 'src/app/shared/models';

@Injectable()
export class CollateralService {

    constructor(
        private afs: AngularFirestore,
        private historyService: HistoryService,
        private activityLogService: ActivityLogService
    ) { }

    getSecurityByDocId = async (docId: string) => {
        return firebase.firestore()
            .collection(constants.FB_LOANS_SECURITY)
            .doc(docId)
            .get();
    }

    getSecurityByLoanId = async (loanId: string) => {
        return firebase.firestore()
            .collection(constants.FB_LOANS_SECURITY)
            .where('loanId', '==', loanId)
            .get();
    }

    getAssetByDocId = async (docId: string) => {
        return firebase.firestore()
            .collection(constants.FB_ASSETS)
            .doc(docId)
            .get();
    }

    getGuarantorByDocId = async (docId: string) => {
        return firebase.firestore()
            .collection(constants.FB_GUARANTORS)
            .doc(docId)
            .get();
    }

    getAssets(clientId: string, clientType: string) {
        return this.afs.collection(constants.FB_ASSETS, ref => {
            return ref.where('clientId', '==', clientId)
                .where('clientType', '==', clientType);
        }).snapshotChanges().pipe(
            map((docs: any) => {
                return docs.map(doc => {
                    const asset = doc.payload.doc.data() as models.Asset;
                    asset.docId = doc.payload.doc.id;
                    return asset;
                });
            })
        );
    }

    getAssetsAsync = async (clientId: string, clientType: string) => {
        return firebase.firestore()
            .collection(constants.FB_ASSETS)
            .where('clientId', '==', clientId)
            .where('clientType', '==', clientType)
            .get();
    }

    getGuarantors() {
        return this.afs.collection(constants.FB_GUARANTORS, ref => {
            return ref; // .where("userId", "==", this.userId);
        }).snapshotChanges().pipe(
            map((docs: any) => {
                return docs.map(doc => {
                    const guarantor = doc.payload.doc.data() as models.Guarantor;
                    guarantor.docId = doc.payload.doc.id;
                    return guarantor;
                });
            })
        );
    }

    doCreateAssetSecurity = async (adminUser: firebase.User, loan: models.Loan, asset: models.Asset) => {
        const collateral = new models.LoanSecurity();
        collateral.loanId = loan.docId;
        collateral.clientId = loan.client.clientId;
        collateral.clientType = loan.client.type;
        collateral.securityId = asset.docId;
        collateral.securityTitle = asset.title;
        collateral.securityType = enums.LoanSecurityType.ASSET;
        collateral.repaymentRatio = (asset.amountGuaranteed / loan.amountRequested);
        collateral.amountGuaranteed = collateral.initAmountGuaranteed = asset.amountGuaranteed;
        collateral.dateCreated = firebase.firestore.FieldValue.serverTimestamp();
        collateral.lastUpdated = firebase.firestore.FieldValue.serverTimestamp();
        const doc = await this.afs.collection(constants.FB_LOANS_SECURITY).add(Object.assign({}, collateral));
        await this.historyService.addLoanSecurityHistoryItem(adminUser, doc.id, Object.assign({}, collateral));
        await this.activityLogService.addActivityLog(constants.FB_LOANS_SECURITY, 'create', doc.id);
    }

    doCreateGuarantorSecurity = async (adminUser: firebase.User, loan: models.Loan, guarantor: models.Guarantor) => {
        const collateral = new models.LoanSecurity();
        collateral.loanId = loan.docId;
        collateral.clientId = loan.client.clientId;
        collateral.clientType = loan.client.type;
        collateral.securityId = guarantor.docId;
        collateral.securityType = enums.LoanSecurityType.GUARANTOR;
        collateral.securityTitle = `${guarantor.firstName} ${guarantor.lastName}`;
        collateral.repaymentRatio = (guarantor.amountGuaranteed / loan.amountRequested);
        collateral.amountGuaranteed = collateral.initAmountGuaranteed = guarantor.amountGuaranteed;
        collateral.dateCreated = firebase.firestore.FieldValue.serverTimestamp();
        collateral.lastUpdated = firebase.firestore.FieldValue.serverTimestamp();
        const doc = await this.afs.collection(constants.FB_LOANS_SECURITY).add(Object.assign({}, collateral));
        await this.historyService.addLoanSecurityHistoryItem(adminUser, doc.id, Object.assign({}, collateral));
    }

    doUpdateCommittedAssetValue = async (adminUser: firebase.User, asset: models.Asset, amountGuaranteed: number) => {
        asset.committedValue = (asset.committedValue + amountGuaranteed);
        asset.remainingValue = (asset.remainingValue - amountGuaranteed);
        asset.lastUpdated = firebase.firestore.FieldValue.serverTimestamp();
        await this.afs.collection(constants.FB_ASSETS).doc(asset.docId).update({
            committedValue: asset.committedValue,
            remainingValue: asset.remainingValue,
            lastUpdated: asset.lastUpdated
        });
        await this.historyService.addAssetHistoryItem(adminUser, asset.docId, Object.assign({}, asset));
        await this.activityLogService.addActivityLog(constants.FB_ASSETS, 'update', asset.docId);
    }

    doUpdateCommittedGuarantorValue = async (adminUser: firebase.User, guarantor: models.Guarantor, amountGuaranteed: number) => {
        guarantor.committedValue = (guarantor.committedValue + amountGuaranteed);
        guarantor.remainingValue = (guarantor.remainingValue - amountGuaranteed);
        guarantor.lastUpdated = firebase.firestore.FieldValue.serverTimestamp();
        await this.afs.collection(constants.FB_GUARANTORS).doc(guarantor.docId).update({
            committedValue: guarantor.committedValue,
            remainingValue: guarantor.remainingValue,
            lastUpdated: guarantor.lastUpdated
        });
        await this.historyService.addGuarantorHistoryItem(adminUser, guarantor.docId, Object.assign({}, guarantor));
        await this.activityLogService.addActivityLog(constants.FB_GUARANTORS, 'update', guarantor.docId);
    }

    doRepayLoanSecurities = async (adminUser: firebase.User, loanId: string, amountRepaid: number) => {
        console.log(`doRepayLoanSecurities > loanId: ${loanId}, amountRepaid: ${amountRepaid}`);
        const snapshot = await this.getSecurityByLoanId(loanId);
        await Promise.all(snapshot.docs.map(async (doc) => {
            const collateral = doc.data() as models.LoanSecurity;
            const recoveredAmount: number = (collateral.repaymentRatio * amountRepaid);
            console.log(`repaymentRatio: ${collateral.repaymentRatio}, recoveredAmount: ${recoveredAmount}`);
            if (collateral.securityType === enums.LoanSecurityType.ASSET) {
                const snapshot2 = await this.getAssetByDocId(collateral.securityId);
                if (snapshot2.exists) {
                    const asset = snapshot2.data() as models.Asset;
                    asset.docId = snapshot2.id;
                    asset.status = enums.LoanSecurityStatus.LOAN_REPAID;

                    await this.doUpdateCommittedAssetValue(adminUser, asset, -recoveredAmount);
                }
            } else if (collateral.securityType === enums.LoanSecurityType.GUARANTOR) {
                const snapshot2 = await this.getGuarantorByDocId(collateral.securityId);
                if (snapshot2.exists) {
                    const guarantor = snapshot2.data() as models.Guarantor;
                    guarantor.docId = snapshot2.id;
                    guarantor.status = enums.LoanSecurityStatus.LOAN_REPAID;

                    await this.doUpdateCommittedGuarantorValue(adminUser, guarantor, -recoveredAmount);
                }
            }
            collateral.amountGuaranteed -= recoveredAmount;
            collateral.status = enums.LoanSecurityStatus.LOAN_REPAID;
            collateral.lastUpdated = firebase.firestore.FieldValue.serverTimestamp();
            await doc.ref.update({
                amountGuaranteed: collateral.amountGuaranteed,
                lastUpdated: collateral.lastUpdated // update modified timestamp
            });
            await this.historyService.addLoanSecurityHistoryItem(adminUser, doc.id, Object.assign({}, collateral));
        }));
    }

    doRemoveLoanSecurities = async (adminUser: firebase.User, loanId: string) => {
        const snapshot = await this.getSecurityByLoanId(loanId);
        await Promise.all(snapshot.docs.map(async (doc) => {
            const collateral = doc.data() as models.LoanSecurity;
            if (collateral.securityType === enums.LoanSecurityType.ASSET) {
                const snapshot2 = await this.getAssetByDocId(collateral.securityId);
                if (snapshot2.exists) {
                    const asset = snapshot2.data() as models.Asset;
                    asset.docId = snapshot2.id;
                    asset.status = enums.LoanSecurityStatus.LOAN_REMOVED;

                    await this.doUpdateCommittedAssetValue(adminUser, asset, -collateral.amountGuaranteed);
                }
            } else if (collateral.securityType === enums.LoanSecurityType.GUARANTOR) {
                const snapshot2 = await this.getGuarantorByDocId(collateral.securityId);
                if (snapshot2.exists) {
                    const guarantor = snapshot2.data() as models.Guarantor;
                    guarantor.docId = snapshot2.id;
                    guarantor.status = enums.LoanSecurityStatus.LOAN_REMOVED;

                    await this.doUpdateCommittedGuarantorValue(adminUser, guarantor, -collateral.amountGuaranteed);
                }
            }
            collateral.amountGuaranteed = 0; // reset to zero
            collateral.status = enums.LoanSecurityStatus.LOAN_REMOVED;
            await this.historyService.addLoanSecurityHistoryItem(adminUser, doc.id, Object.assign({}, collateral));
            await doc.ref.delete();
        }));
    }

}
