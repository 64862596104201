export class Business {

  public docId: string;
  public olaId: string;
  public name: string;
  public email: string;
  public regNumber: string;
  public phoneNumber: string;
  public kraPinNumber: string;
  public location: string;
  public sector: string;
  public owner: string;

  public crbConsent: boolean;
  public isQualified: boolean;
  public qualificationReason?: string;
  public overrideQualified?: boolean;
  public overrideQualificationReason?: string;

  public bankName?: string;
  public bankCode?: string;
  public bankAccountNumber?: string;
  public pesalinkPhoneNumber?: string;

  public dateCreated: any;
  public lastUpdated: any;
  public crbConsentDate?: Date;
  public lastDisbursedDate?: any;

  public directors: Array<Director> = [];

}

export class Director {

  public email: string;
  public firstName: string;
  public lastName: string;
  public idNumber: string;
  public phoneNumber: string;
  public description?: string;

}
