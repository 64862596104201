import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as moment from 'moment';

@Injectable()
export class AmortizationService {

    constructor(private afs: AngularFirestore) { }

    getPresentValue(months: number, rate: number, payment: number, type?: number): number {
        rate = rate / 100; type = type || 0;
        const annuityImmediate = payment * ((1 - Math.pow(1 + rate, -months)) / rate);
        const annuityDue = annuityImmediate * (1 + rate);
        return (type === 0) ? annuityImmediate : annuityDue;
    }

    getRemainingBalance(amount: number, months: number, rate: number, payment: number): number {
        rate = rate / 100;
        return amount * Math.pow(1 + rate, months) - payment * ((Math.pow(1 + rate, months) - 1) / rate);
    }

    /**
     * @param amount (required): the starting principal amount of the loan (PV)
     * @param months (required): the number of whole months over which the loan extends (NPER)
     * @param rate (required): the annual interest rate of the loan expressed as a percentage, e.g., 10.5
     * @param firstPaymentDate (optional): the date the first payment will be made
     */
    doGenerateSchedule(amount: number, months: number, rate: number, firstPaymentDate?: Date): any[] {
        rate = rate / 100;
        const schedule: Array<any> = [];
        const currentDate: moment.Moment = firstPaymentDate ? moment(firstPaymentDate) : moment();
        const payment = amount * (rate * Math.pow(1 + rate, months)) / (Math.pow(1 + rate, months) - 1);
        let totalInterest = 0.0;
        let currentPrincipal = 0;
        let currentInterest = 0;
        let balance = amount;
        for (let i = 0; i < months; i++) {
            currentInterest = balance * rate;
            totalInterest += currentInterest;
            currentPrincipal = payment - currentInterest;
            balance -= currentPrincipal;

            schedule.push({
                paymentNumber: i + 1,
                principle: parseFloat(balance.toFixed(2)),
                accumulatedInterest: parseFloat(totalInterest.toFixed(2)),
                payment: parseFloat(payment.toFixed(2)),
                paymentToPrinciple: parseFloat(currentPrincipal.toFixed(2)),
                paymentToInterest: parseFloat(currentInterest.toFixed(2)),
                date: currentDate.toISOString()
            });

            currentDate.add(1, 'months');

        }

        return schedule;
    }

    doGenerateSchedule2(amount: number, months: number, rate: number, firstPaymentDate?: Date): any[] {
        const currentDate: moment.Moment = firstPaymentDate ? moment(firstPaymentDate) : moment();
        const currentPrincipal = amount / months;
        const schedule: Array<any> = [];
        let totalInterest = 0.0;
        let currentInterest = 0;
        let balance = amount;
        for (let i = 0; i < months; i++) {
            currentInterest = (rate / 100) * balance;
            totalInterest += currentInterest;
            balance -= currentPrincipal;

            schedule.push({
                paymentNumber: i + 1,
                principle: balance,
                accumulatedInterest: totalInterest,
                payment: (currentPrincipal + currentInterest),
                paymentToPrinciple: currentPrincipal,
                paymentToInterest: currentInterest,
                date: currentDate.toISOString()
            });

            currentDate.add(1, 'months');

        }

        return schedule;
    }

}
