export enum LoanSecurityStatus {

  LOAN_ADDED = 'loan_added',
  LOAN_REMOVED = 'loan_removed',
  LOAN_REPAID = 'loan_repaid'

}

export enum LoanSecurityType {

  ASSET = 'asset',
  GUARANTOR = 'guarantor'

}

export enum AssetType {

  LOG_BOOK = 'logbook',
  TITLE_DEED = 'titledeed'

}
