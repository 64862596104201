export class Appraisal {
  public docId: string;
  public idNumber: string;
  public clientName: string;
  public businessName: string;
  public businessType: string;
  public businessTypeOther: string;
  public businessLicenseNo: string;
  public businessOwnership: string;
  public businessCounty: string;
  public businessLocation: string;

  public assignedLimit: number;
  public guarantor: string;
  public loanPurpose: string;
  public comments: string;
  public secondaryAuthorizer: string;

  public assessedBy: any;
  public dateAssessed: any;
  public updatedBy: any;
  public lastUpdated: any;

  public docExists = false;

}
