export enum LoanDutyType {
  PROCESSING = 'processing',
  RENEWAL = 'renewal'

}

export enum LoanDutyCategory {
  NORMAL = 'normal',
  SPECIAL = 'special',
  V2 = 'v2'
}
