import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { HistoryService } from './history.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminUser, Appraisal, Client } from '../models';
import { constants } from '../constants';
import { AdminService } from './admin.service';
import { ActivityLogService } from './activity.log.service';

@Injectable()
export class ClientService {

    constructor(
        private afs: AngularFirestore,
        private adminService: AdminService,
        private historyService: HistoryService,
        private activityLogService: ActivityLogService,
    ) { }

    getByDocId = (docId: string) => {
        const usersCollection = this.afs.collection(constants.FB_CLIENTS);
        return usersCollection
            .doc(docId)
            .get();
    }

    getByDocId2 = async (docId: string) => {
        return firebase.firestore()
            .collection(constants.FB_CLIENTS).doc(docId)
            .get();
    }

    getByIdNumber = async (idNumber: string) => {
        return firebase.firestore()
            .collection(constants.FB_CLIENTS)
            .where('idNumber', '==', idNumber)
            .get();
    }

    getByPhoneNumber = async (phoneNumber: string) => {
        return firebase.firestore()
            .collection(constants.FB_CLIENTS)
            .where('phoneNumber', '==', phoneNumber)
            .get();
    }

    getClientsCount() {
        return this.afs
            .collection(constants.FB_COUNTERS)
            .doc(constants.FB_CLIENTS)
            .valueChanges();
    }

    getAppraisal(userId: string): Observable<Appraisal> {
        const appraisalCollections = this.afs.collection(constants.FB_CLIENTS_APPRAISALS);
        return appraisalCollections.doc(userId).snapshotChanges().pipe(
            map(action => {
                if (action.payload.exists) {
                    const appraisal = action.payload.data() as Appraisal;
                    appraisal.docExists = true;
                    const docId = action.payload.id;
                    return { docId, ...appraisal };
                } else {
                    return new Appraisal();
                }
            })
        );
    }

    getMobileMoneyName = async (phoneNumber: string) => {
        let mobileMoneyName = 'NAME NOT FOUND';
        const snapshot = await this.afs.collection(constants.FB_MPESA_MANUAL_TRANSACTIONS).ref
            .where('ReceiverPartyMSISDN', '==', phoneNumber)
            .get();

        if (!snapshot.empty) {
            const transaction = snapshot.docs[0].data() as any;
            mobileMoneyName = transaction.ReceiverPartyName;
        }

        return mobileMoneyName;
    }

    doAddUser = async (user: Client, adminUser: firebase.User | AdminUser) => {
        const userId = this.afs.createId();
        await this.afs.collection(constants.FB_CLIENTS).doc(userId).set(user);
        await this.historyService.addUserHistoryItem(adminUser, userId, user);
        await this.activityLogService.addActivityLog(constants.FB_CLIENTS, 'create', userId);
    }

    doUpdateUser = async (adminUser: firebase.User | AdminUser, userId: string, changes: any) => {
        await this.afs.collection(constants.FB_CLIENTS).doc(userId).update(changes);
        await this.historyService.addUserHistoryItem(adminUser, userId, changes);
        await this.activityLogService.addActivityLog(constants.FB_CLIENTS, 'update', userId);
    }

    markForApproval = async (adminUser: firebase.User, currentUserData: any, userId: string, type: string): Promise<void> => {
        const changes: any = {
            hasPendingAction: true,
            pendingAction: {
                type,
                loanLimit: currentUserData.loanLimit,
                loanRate: currentUserData.loanRate,
                progressionAmount: currentUserData.progressionAmount,
                progressionCap: currentUserData.progressionCap,

                doneBy: adminUser.uid,
                doneByEmail: adminUser.email,
                doneOn: firebase.firestore.FieldValue.serverTimestamp()
            }
        };
        await this.doUpdateUser(adminUser, userId, changes);
    }

    doInitLimitUpdate = async (adminUser: firebase.User, user: Client, type: string) => {
        console.log('doInitLimitUpdate: ', user.loanLimit, type);

        const changes: any = {
            hasPendingAction: true,
            pendingAction: {
                type,
                loanLimit: user.loanLimit,
                progression: {
                    cap: user.progression.cap,
                    amount: user.progression.amount
                },
                doneBy: adminUser.uid,
                doneByEmail: adminUser.email,
                doneOn: firebase.firestore.FieldValue.serverTimestamp()
            }
        };

        if ((type === 'loan_limit') && (user.loanLimit > 50000)) {
            const adminsList = await this.adminService.getFinanceCommittee();
            const canApproveIdList = adminsList.map((admin) => admin.uid);
            console.log('doInitLimitUpdate > canApproveIdList:', canApproveIdList);

            changes.pendingAction.canApprove = canApproveIdList;
            changes.pendingAction.minApprovingCount = 2;
            changes.pendingAction.approvedBy = [];
        }

        await this.doUpdateUser(adminUser, user.docId, changes);
    }


    doConfirmLimitChange = async (adminUser: firebase.User, user: Client) => {
        user.hasPendingAction = false;
        user.loanLimit = user.pendingAction.loanLimit;
        user.progression = {
            cap: user?.progression?.cap || user?.pendingAction?.loanLimit,
            amount: user?.progression?.amount || 0
        };
        user.pendingAction = {};

        await this.doUpdateUser(adminUser, user.docId, user);
    }


    doCancelLimitChange = async (adminUser: firebase.User, user: Client) => {
        const changes: any = {
            pendingAction: null,
            hasPendingAction: false
        };

        await this.doUpdateUser(adminUser, user.docId, changes);
    }

    doDisqualifyUser = async (adminUser: firebase.User, userId: string, qualificationReason: string) => {
        const changes: any = {
            qualified: {
                status: false,
                reason: qualificationReason,
                date: firebase.firestore.FieldValue.serverTimestamp(),
                actor: { uid: adminUser.uid, email: adminUser.email }
            },
        };
        await this.doUpdateUser(adminUser, userId, changes);
    }

    doQualifyUser = async (adminUser: firebase.User, userId: string, qualificationReason: string) => {
        const changes: any = {
            qualified: {
                status: true,
                reason: qualificationReason,
                date: firebase.firestore.FieldValue.serverTimestamp(),
                actor: { uid: adminUser.uid, email: adminUser.email }
            }
        };
        await this.doUpdateUser(adminUser, userId, changes);
    }

    doDeleteUser = async (adminUser: firebase.User, client: Client): Promise<void> => {
        await this.afs.collection(constants.FB_CLIENTS_REMOVED).add(client);
        await this.afs.collection(constants.FB_CLIENTS).doc(client.docId).delete();
        await this.historyService.addUserHistoryItem(adminUser, client.docId, {
            deletedBy: adminUser.uid,
            deletedByEmail: adminUser.email,
            dateDeleted: firebase.firestore.FieldValue.serverTimestamp()
        });
        await this.activityLogService.addActivityLog(constants.FB_CLIENTS, 'delete', client.docId);
    }

}
