import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { constants } from 'src/app/shared/constants';
import * as models from 'src/app/shared/models';
import * as moment from 'moment';
import firebase from 'firebase/compat/app';

@Injectable()
export class InstalmentService {

    constructor(
        private afs: AngularFirestore,
    ) { }

    getByDocId(instalmentId: string) {
        return this.afs
            .collection(constants.FB_LOANS_INSTALMENTS)
            .doc(instalmentId).ref
            .get();
    }

    getByLoanId(loanId: string) {
        return this.afs.collection(constants.FB_LOANS_INSTALMENTS).ref
            .where('loanId', '==', loanId)
            .get();
    }

    getByPaymentNumber(loanId: string, paymentNumber: number) {
        return this.afs.collection(constants.FB_LOANS_INSTALMENTS).ref
            .where('paymentNumber', '==', paymentNumber)
            .where('loanId', '==', loanId)
            .get();
    }

    getRowToDates = async (loanId: string, lastDate: Date) => {
        return this.afs.collection(constants.FB_LOANS_INSTALMENTS).ref
            .where('loanId', '==', loanId)
            .where('dueDate', '<=', lastDate)
            .get();
    }

    getRowByDates = async (loanId: string, firstDate: Date, lastDate: Date) => {
        return this.afs.collection(constants.FB_LOANS_INSTALMENTS).ref
            .where('loanId', '==', loanId)
            .where('dueDate', '>=', firstDate)
            .where('dueDate', '<=', lastDate)
            .get();
    }

    getSeasonRow = async (loanId: string, dayOfTheMonth: number) => {
        const firstDay = moment({ day: dayOfTheMonth }).endOf('day').toDate();
        const lastDay = moment({ day: dayOfTheMonth }).add(1, 'months').endOf('day').toDate();
        return this.getRowByDates(loanId, firstDay, lastDay);
    }

    doProcessPayment = async (seasonRow: models.Instalment, transactionAmount: number) => {
        let seasonRowAmountToRepay = 0;
        if (seasonRow.amountToRepay > transactionAmount) {
            seasonRowAmountToRepay = (seasonRow.amountToRepay - transactionAmount);
        }

        await this.afs.collection(constants.FB_LOANS_INSTALMENTS).doc(seasonRow.docId).update({
            amountPaid: (seasonRow.amountPaid + transactionAmount),
            amountToRepay: Math.ceil(seasonRowAmountToRepay),
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        });

        if (seasonRow.amountToRepay < transactionAmount) {
            console.log('Excess season payment:', (transactionAmount - seasonRow.amountToRepay));
            // TODO: process excess season payment
        }
    }

    doGenerateSchedule2 = (loan: models.Loan) => {
        // console.log("doGenerateSchedule2 > loan:", loan);
        const schedule: Array<any> = [];
        const currentDate = loan.firstPaymentAt ? moment(loan.firstPaymentAt) : moment();
        const currentPrincipal = loan.principalToRepay / loan.duration;
        const currentInterest = loan.interestToRepay / loan.duration;
        let totalInterest = 0.0;
        let balance = loan.amountRequested;

        for (let i = 0; i < loan.duration; i++) {
            totalInterest += currentInterest;
            balance -= currentPrincipal;

            schedule.push({
                paymentNumber: i + 1,
                principle: parseFloat(balance.toFixed(2)),
                payment: parseFloat((currentPrincipal + currentInterest).toFixed(2)),
                paymentToPrinciple: parseFloat(currentPrincipal.toFixed(2)),
                paymentToInterest: parseFloat(currentInterest.toFixed(2)),
                accumulatedInterest: parseFloat(totalInterest.toFixed(2)),
                date: currentDate.toISOString()
            });

            currentDate.add(30, 'days');
        }

        return schedule;
    }


}
