export const BANK_CODES_MAP = [
  { value: '01', display: 'Kenya Commercial Bank Limited, KCB' },
  { value: '02', display: 'Standard Chartered Bank Kenya Limited, SCBK' },
  { value: '03', display: 'Absa Bank Kenya PLC, ABSA' },
  { value: '05', display: 'Bank of India, BOI' },
  { value: '06', display: 'Bank of Baroda (Kenya) Limited, BBK' },
  { value: '07', display: 'Commercial Bank of Africa Limited, CBA' },
  { value: '08', display: 'Habib Bank Limited, HB' },
  { value: '09', display: 'Central Bank of Kenya, CBK' },
  { value: '10', display: 'Prime Bank Limited, PB' },
  { value: '11', display: 'Co - operative Bank of Kenya Limited, COOP' },
  { value: '12', display: 'National Bank of Kenya Limited, NBK' },
  { value: '14', display: 'M - ORIENTAL BANK LIMITED, OB' },
  { value: '16', display: 'Citibank, CITI' },
  { value: '17', display: 'Habib Bank A.G.Zurich, HBAG' },
  { value: '18', display: 'Middle East Bank Kenya Limited, MEBK' },
  { value: '19', display: 'Bank of Africa, BOA' },
  { value: '23', display: 'Consolidated Bank of Kenya Limited, CONS' },
  { value: '25', display: 'Credit Bank Limited, CB' },
  { value: '26', display: 'Trans - National Bank Limited, TNB' },
  { value: '30', display: 'Chase Bank(Kenya) Limited, CHASE' },
  { value: '31', display: 'CfC Stanbic BankLimited, CCSB' },
  { value: '61', display: 'Housing Finance, HF' },
  { value: '35', display: 'African Banking Corporation, ABC' },
  { value: '41', display: 'National Industrial Credit Bank, NIC' },
  { value: '42', display: 'Giro Bank Limited, GIRO' },
  { value: '43', display: 'Ecobank, ECB' },
  { value: '49', display: 'Spire Bank Limited, SB' },
  { value: '50', display: 'Paramount Bank Limited, PAR' },
  { value: '51', display: 'Jamii Bora Bank Ltd, JBB' },
  { value: '53', display: 'Guaranty Trust(Kenya) Ltd, GTK' },
  { value: '54', display: 'Victoria Commercial Bank Limited, VCB' },
  { value: '55', display: 'Guardian Bank Limited, GB' },
  { value: '57', display: 'Investments & Mortgages Bank Limited, IMB' },
  { value: '59', display: 'Development Bank of Kenya Limited, DBK' },
  { value: '60', display: 'Fidelity Commercial Bank Limited, FCB' },
  { value: '63', display: 'Diamond Trust Bank Limited, DTB' },
  { value: '66', display: 'Sidian Bank Limited, SID' },
  { value: '68', display: 'EQUITY BANK LIMITED, EBL' },
  { value: '70', display: 'Family Bank, FB' },
  { value: '72', display: 'Gulf Bank Limited, GULF' },
  { value: '74', display: 'First Community  Bank, FC' },
  { value: '76', display: 'UBA KENYA BANK LTD, UBA' },
  { value: '78', display: 'Kenya Women MicroFinance Bank limited, KWMF' },
  { value: '79', display: 'Faulu MicroFinance Bank ltd, FMF' },
];
