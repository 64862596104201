import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { constants } from 'src/app/shared/constants';
import { AdminUser } from 'src/app/shared/models/admin.model';
import * as models from 'src/app/shared/models';
import firebase from 'firebase/compat/app';

@Injectable()
export class HistoryService {
  constructor(private afs: AngularFirestore) {}

  addLoanHistoryItem = async (
    adminUser: firebase.User | AdminUser,
    loanId: string,
    loan: models.Loan,
    addToStatement: boolean = false
  ) => {
    console.log(`addLoanHistoryItem > loanId: ${loanId}, status:`, loan.status);

    try {
      const data: any = {};
      data.status = loan.status;
      data.sendTo = loan.sendTo;
      data.initAmountToRepay = loan.initAmountToRepay;
      data.amountRequested = loan.amountRequested;
      data.amountToDisburse = loan.amountToDisburse;
      data.monthlyPayment = loan.monthlyPayment;
      data.firstPaymentAt = loan.firstPaymentAt;
      data.requestedAt = loan.requestedAt;
      data.dueAt = loan.dueAt;
      data.duration = loan.duration;
      data.currency = loan.currency;
      data.clientType = loan.client.type;
      data.clientId = loan.client.clientId;
      data.owner = loan.owner;
      if (loan.rollOverAmountToRepay) {
        data.rollOverAmountToRepay = loan.rollOverAmountToRepay;
      }
      if (loan.transactionAmount) {
        data.transactionAmount = loan.transactionAmount;
      }
      if (loan.initialAmounts) data.initialAmounts = loan.initialAmounts;

      const snapshot = await this.afs
        .collection(constants.FB_LOANS_HISTORY)
        .doc(loanId)
        .ref.get();

      if (snapshot.exists) {
        await snapshot.ref.update(data);
      } else {
        await snapshot.ref.set(data);
      }

      const changes: any = {
        addToStatement,
        amountRequested: loan.amountRequested,
        amountToRepay: loan.amountToRepay,
        principalToRepay: loan.principalToRepay,
        interestToRepay: loan.interestToRepay,
        interest: loan.interest,
        status: loan.status,
        duration: loan.duration,
        doneBy: adminUser?.uid,
        doneByEmail: adminUser?.email,
        actionDate: firebase.firestore.FieldValue.serverTimestamp(),
      };

      if (loan.nextDueAt) changes.nextDueDate = loan.nextDueAt;
      if (loan.previousStatus) changes.previousStatus = loan.previousStatus;
      if (loan.currentInstalment) {
        changes.currentInstalment = loan.currentInstalment;
      }
      if (loan.transactionCode)
        changes.transactionCode = loan.transactionCode;
      if (loan.approvedAt) changes.dateApproved = loan.approvedAt;
      if (loan.disbursedAt) changes.disbursedAt = loan.disbursedAt;
      if (loan.amountPaid) changes.amountPaid = loan.amountPaid;
      if (loan.interestPaid) changes.interestPaid = loan.interestPaid;
      if (loan.principalPaid) changes.principalPaid = loan.principalPaid;
      if (loan.processingFeePaid) {
        changes.processingFeePaid = loan.processingFeePaid;
      }
      if (loan.rollOverAmountToRepay) {
        changes.rollOverAmountToRepay = loan.rollOverAmountToRepay;
      }
      if (loan.transactionAmount) {
        changes.transactionAmount = loan.transactionAmount;
      }
      if (loan.note) changes.note = loan.note;

      await snapshot.ref
        .collection(constants.FB_LOANS_HISTORY_ITEMS)
        .add(changes);
    } catch (error) {
      console.error('ADD LOAN HISTORY ITEM ERROR:::', error);
    }
  }

  addLoanHistoryItemProperty = async (
    loanId: string,
    update: any,
    loan: models.Loan
  ) => {
    const data: any = {};
    data.status = loan.status;
    data[update.key] = update;

    const historyCollection = this.afs.collection(constants.FB_LOANS_HISTORY);
    await historyCollection.doc(loanId).update(data);
  }

  addUserHistoryItem = async (
    adminUser: firebase.User | AdminUser,
    userId: string,
    changes: any
  ) => {
    // console.log(`addUserHistoryItem > userId: ${userId}, changes: ${JSON.stringify(changes)}`);
    delete changes.docId;
    delete changes.lastUpdated;

    changes.doneBy = adminUser.uid;
    changes.doneByEmail = adminUser.email;
    changes.actionDate = firebase.firestore.FieldValue.serverTimestamp();

    const historyDoc = this.afs.collection(constants.FB_CLIENTS).doc(userId);
    await historyDoc.collection(constants.FB_HISTORY).add(changes);
  }

  addLoanSecurityHistoryItem = async (
    adminUser: firebase.User | AdminUser,
    docId: string,
    changes: any
  ) => {
    console.log(
      `addLoanSecurityHistoryItem > docId: ${docId}, changes:`,
      changes
    );
    changes.doneBy = adminUser.uid;
    changes.doneByEmail = adminUser.email;
    changes.actionDate = firebase.firestore.FieldValue.serverTimestamp();

    const assetsCollection = this.afs.collection(constants.FB_LOANS_SECURITY);
    await assetsCollection
      .doc(docId)
      .collection(constants.FB_HISTORY)
      .add(changes);
  }

  addAssetHistoryItem = async (
    adminUser: firebase.User | AdminUser,
    docId: string,
    changes: any
  ) => {
    console.log(`addAssetHistoryItem > docId: ${docId}, changes:`, changes);
    changes.doneBy = adminUser.uid;
    changes.doneByEmail = adminUser.email;
    changes.actionDate = firebase.firestore.FieldValue.serverTimestamp();

    const assetsCollection = this.afs.collection(constants.FB_ASSETS);
    await assetsCollection
      .doc(docId)
      .collection(constants.FB_HISTORY)
      .add(changes);
  }

  addGuarantorHistoryItem = async (
    adminUser: firebase.User | AdminUser,
    docId: string,
    changes: any
  ) => {
    console.log(`addGuarantorHistoryItem > docId: ${docId}, changes:`, changes);
    changes.doneBy = adminUser.uid;
    changes.doneByEmail = adminUser.email;
    changes.actionDate = firebase.firestore.FieldValue.serverTimestamp();

    const guarantorsCollection = this.afs.collection(constants.FB_GUARANTORS);
    await guarantorsCollection
      .doc(docId)
      .collection(constants.FB_HISTORY)
      .add(changes);
  }

  logExcessPaidAmounts = async (
    userId: string,
    excessAmount: number,
    transaction: any
  ) => {
    console.log(
      `logExcessPaidAmounts > userId: ${userId}, excessAmount: ${excessAmount}`
    );
    const excessAmountsCollection = firebase
      .firestore()
      .collection(constants.FB_EXCESS_PAID_AMOUNTS);
    await excessAmountsCollection.add({
      userId,
      isViewed: false,
      excessAmount,
      msisdn: transaction.msisdn,
      transId: transaction.transId,
      transTime: transaction.transTime,
      transAmount: transaction.transAmount,
      billRefNumber: transaction.billRefNumber,

      doneBy: transaction.doneBy,
      doneByEmail: transaction.doneByEmail,
      actionDate: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  addMessageHistoryItem = async (
    adminUser: firebase.User | AdminUser,
    messageId: string,
    changes: any
  ) => {
    changes.doneBy = adminUser.uid;
    changes.doneByEmail = adminUser.email;
    changes.actionDate = firebase.firestore.FieldValue.serverTimestamp();
    const historyCollection = this.afs.collection(
      constants.FB_MESSAGES_HISTORY
    );
    await historyCollection
      .doc(messageId)
      .collection(constants.FB_SUB_ITEMS)
      .add(changes);
  }
}
