export enum MaxDisbuseAmounts {

  MPESA = 70000,
  PAYBILL = 70000,
  BANK = 350000

}

export enum LoanType {
  WEB = 'web',
  USSD = 'ussd',
  MOBILE = 'mobile'
}

export enum LoanDisburseOption {
  BANK = 'bank',
  CHEQUE = 'cheque',
  MOBILE_MONEY = 'mobile-money',
  PAYBILL = 'paybill'
}

export enum LoanClient {

  PERSON = 'person', // individual
  BUSINESS = 'business' // company

}

export enum LoanStatus {

  PENDING = 'pending',
  DECLINED = 'declined',
  APPROVED = 'approved',
  DISBURSED = 'disbursed',
  OVERDUE = 'overdue',
  LISTED = 'listed',
  OUTSOURCED = 'outsourced',
  WRITTEN_OFF = 'writtenOff',

  REPAID = 'repaid',
  REPAID_PARTIAL = 'repaid_partial',
  CANCELLED = 'cancelled',

  CONFIRM_MANUAL_DECLINE = 'confirm_manual_decline',
  MANUAL_DECLINE = 'manual_decline',
  OVERDUE_ROLLOVER = 'overdue_rollover',
  OVERDUE_ROLLOVER_STOP = 'overdue_rollover_stop',

  CANCELLED_MANUALLY = 'cancelled_manually',
  CANCELLED_CONFIRMED = 'cancelled_confirmed',
  INITIATE_DISBURSE_MANUALLY = 'initiate_disburse_manually',
  DISBURSED_MANUALLY = 'disbursed_manually',
  REASSESSED_MANUALLY = 're_assessed_manually',
  RENEWED_MANUALLY = 'renewed_manually',
  REPAID_MANUALLY = 'repaid_manually',
  REPAID_CONFIRMED = 'repaid_confirmed',
  REPAID_PARTIAL_MANUALLY = 'repaid_partial_manually',
  REPAID_PARTIAL_CONFIRMED = 'repaid_partial_confirmed',
  PAID_IN_TIME_MANUALLY = 'paid_in_time_manually',
  RATE_ADJUSTED_MANUALLY = 'rate_adjusted_manually',
  MOVED_MANUALLY = 'moved_manually',
  LOW_LIMIT = 'low_user_loan_limit',
  MARKED_AS_FRAUD = 'marked_as_fraud',
  ABORT_CONFIRM_AS_FRAUD = 'abort_confirm_action',
  RESTORED_MANUALLY = 'restored_manually',

}

export enum LoanPendingAction {
  cancel = 'cancel',
  fraud = 'fraud',
  repay = 'repay',
  waive = 'waive',
  restructure = 'restructure',
  manual_add = 'manual_add',
  disburse = 'disburse',
  decline = 'decline'
}
