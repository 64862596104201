import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { constants } from 'src/app/shared/constants';
import firebase from 'firebase/compat/app';

@Injectable()
export class BusinessService {

    constructor(
        private afs: AngularFirestore,
    ) { }

    getByDocId(docId: string): Promise<firebase.firestore.DocumentSnapshot> {
        return this.afs.collection(constants.FB_BUSINESSES).doc(docId).ref.get();
    }

    getByName(name: string): Promise<firebase.firestore.QuerySnapshot> {
        return firebase.firestore()
            .collection(constants.FB_BUSINESSES)
            .where('name', '==', name)
            .get();
    }

    getByEmail(email: string): Promise<firebase.firestore.QuerySnapshot> {
        return firebase.firestore()
            .collection(constants.FB_BUSINESSES)
            .where('email', '==', email)
            .get();
    }

    getByPhoneNumber(phoneNumber: string): Promise<firebase.firestore.QuerySnapshot> {
        return firebase.firestore()
            .collection(constants.FB_BUSINESSES)
            .where('phoneNumber', '==', phoneNumber)
            .get();
    }

    getByRegNumber(regNumber: string): Promise<firebase.firestore.QuerySnapshot> {
        return firebase.firestore()
            .collection(constants.FB_BUSINESSES)
            .where('regNumber', '==', regNumber)
            .get();
    }

    getByKraPinNumber(kraPinNumber: string): Promise<firebase.firestore.QuerySnapshot> {
        return firebase.firestore()
            .collection(constants.FB_BUSINESSES)
            .where('kraPinNumber', '==', kraPinNumber)
            .get();
    }

    getByBankCodeAndAccount(bankCode: string, accountNumber: string): Promise<firebase.firestore.QuerySnapshot> {
        return firebase.firestore()
            .collection(constants.FB_BUSINESSES)
            .where('bankAccountNumber', '==', accountNumber)
            .where('bankCode', '==', bankCode)
            .get();
    }

    getByPeslinkPhoneNumber(phoneNumber: string): Promise<firebase.firestore.QuerySnapshot> {
        return firebase.firestore()
            .collection(constants.FB_BUSINESSES)
            .where('pesalinkPhoneNumber', '==', phoneNumber)
            .get();
    }

    doSave = async (adminUser: firebase.User, changes: any): Promise<string> => {
        const doc = await this.afs.collection(constants.FB_BUSINESSES).add(changes);
        // await this.historyService.addBusinessHistoryItem(adminUser, docId, changes);

        return doc.id;
    }

    doUpdate = async (adminUser: firebase.User, docId: string, changes: any) => {
        await this.afs.collection(constants.FB_BUSINESSES).doc(docId).update(changes);
        // await this.historyService.addBusinessHistoryItem(adminUser, docId, changes);
    }

}
