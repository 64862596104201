import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { constants } from 'src/app/shared/constants';
import { HistoryService } from './history.service';
import firebase from 'firebase/compat/app';

@Injectable()
export class SmsService {

    constructor(
        private afs: AngularFirestore,
        private historyService: HistoryService
    ) { }

    sendSms = async (adminUser: firebase.User, changes: any) => {
        changes.sentBy = adminUser.uid;
        changes.sentByEmail = adminUser.email;
        const doc = await this.afs.collection(constants.FB_MESSAGES).add(changes);
        await this.historyService.addMessageHistoryItem(adminUser, doc.id, changes);
    }

}
