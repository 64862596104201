export class FollowUp {

  public docId: string;
  public loanId: string;
  public clientId: string;
  public type = 'call'; // bank, mpesa
  public amount: number;

  public commitmentDate: any;
  public dueDate: number;
  public date: any;

  public lineStatus: string;
  public doneByEmail: string;
  public doneBy: string;

  public note: string;
  public answeredNote?: string;

}
