import { LoanStatus } from './../enums/loan.enums';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CollateralService } from './collateral.service';
import { HistoryService } from './history.service';
import { AdminUser } from '../models/admin.model';
import { Loan } from '../models/loan.model';
import { LoanPendingAction } from '../enums';
import { constants } from '../constants';
import firebase from 'firebase/compat/app';
import * as moment from 'moment';

@Injectable()
export class LoanService {
  constructor(
    private afs: AngularFirestore,
    private historyService: HistoryService,
    private collateralService: CollateralService
  ) {}

  getByDocId = async (docId: string) => {
    return firebase.firestore().collection(constants.FB_LOANS).doc(docId).get();
  }

  getActiveLoans = async (clientId: string, clientType: string) => {
    return firebase
      .firestore()
      .collection(constants.FB_LOANS)
      .where('clientId', '==', clientId)
      .where('clientType', '==', clientType)
      .get();
  }

  getDisbursedLoans = async (userId: string) => {
    return firebase
      .firestore()
      .collection(constants.FB_LOANS)
      .where('userId', '==', userId)
      .where('isDisbursed', '==', true)
      .orderBy('dateDisbursed', 'asc')
      .get();
  }

  getIsRepayable(loan: Loan): boolean {
    let isRepayable = false;
    if (loan !== undefined) {
      isRepayable = [
        LoanStatus.DISBURSED.toString(),
        LoanStatus.OVERDUE.toString(),
        LoanStatus.LISTED.toString(),
        LoanStatus.OUTSOURCED.toString(),
        LoanStatus.WRITTEN_OFF.toString(),
      ].includes(loan.status);
    }

    return isRepayable;
  }

  getEarlyRepayment = (loan: Loan) => {
    let interestRate = loan.interest / 100;
    let interestToRepay = loan.interestToRepay;
    const principalToRepay = loan.principalToRepay || 0;
    const rollOverAmountToRepay = loan.rollOverAmountToRepay || 0;

    loan.disbursedAt = loan.disbursedAt || loan.requestedAt;
    const days = moment().diff(moment(loan.disbursedAt.toDate()), 'days') + 1;

    if (days <= 7) {
      interestRate = constants.LOAN_1WEEK_RATE;
      interestToRepay = principalToRepay * interestRate;
    } else if (days > 7 && days <= 14) {
      interestRate = constants.LOAN_2WEEKS_RATE;
      interestToRepay = principalToRepay * interestRate;
    } else if (days > 14 && days <= 30) {
      interestToRepay = principalToRepay * interestRate;
    }

    let amountToRepay =
      principalToRepay + interestToRepay + rollOverAmountToRepay;
    // console.log(`days: ${days}, principalToRepay: ${principalToRepay}, interestToRepay: ${interestToRepay}, amountToRepay: ${amountToRepay}`);

    // Handle custom interest cases
    if (amountToRepay > loan.amountToRepay) {
      amountToRepay = loan.amountToRepay;
      interestToRepay = loan.interestToRepay;
    }

    return {
      amountToRepay,
      principalToRepay,
      interestToRepay,
      rollOverAmountToRepay,
      interest: interestRate * 100,
    };
  }

  getCollectionName(path: string): string {
    let collection: string = constants.FB_LOANS;
    if (['repaid'].includes(path)) collection = constants.FB_LOANS_REPAID;
    else if (['removed', 'cancelled'].includes(path))
      collection = constants.FB_LOANS_REMOVED;

    return collection;
  }

  getCanAddMoreLoans = async (clientId: string, clientType: string) => {
    let canAddMoreLoans = false;
    const snapshot = await this.getActiveLoans(clientId, clientType);
    if (snapshot.size < 3) canAddMoreLoans = true;
    else if (snapshot.size >= 5) canAddMoreLoans = false;
    else {
      const snapshot2 = await this.collateralService.getAssetsAsync(
        clientId,
        clientType
      );
      canAddMoreLoans = !snapshot2.empty; // (snapshot2.size > 0)
    }

    return canAddMoreLoans;
  }

  doCancelLoan = async (adminUser: firebase.User, loan: Loan) => {
    loan.status = LoanStatus.CANCELLED;
    loan.canceledAt = firebase.firestore.FieldValue.serverTimestamp();
    loan.meta.updatedBy.uid = adminUser.uid;
    loan.meta.updatedBy.email = adminUser.email;

    const loanDocumentRef = this.afs
      .collection(constants.FB_LOANS)
      .doc(loan.docId);
    const loanInstallmentsDocumentRef = firebase
      .firestore()
      .collection(constants.FB_LOANS_INSTALMENTS);
    const removedInstallmentsDocumentRef = firebase
      .firestore()
      .collection(constants.FB_LOANS_INSTALMENTS_REMOVED);
    const removedDoc = await this.afs
      .collection(constants.FB_LOANS_REMOVED)
      .add(Object.assign({}, loan));
    await this.historyService.addLoanHistoryItem(
      adminUser,
      loan.docId,
      loan,
      true
    );

    const paymentsSnapshot = await loanDocumentRef
      .collection(constants.FB_PAYMENTS)
      .ref.get();
    await Promise.all(
      paymentsSnapshot.docs.map(
        async (doc: firebase.firestore.DocumentSnapshot) => {
          await removedDoc
            .collection(constants.FB_PAYMENTS)
            .add(Object.assign({}, doc.data()));
          await doc.ref.delete();
        }
      )
    );

    const disbursementsSnapshot = await loanDocumentRef
      .collection(constants.FB_DISBURSEMENTS)
      .ref.get();
    await Promise.all(
      disbursementsSnapshot.docs.map(
        async (doc: firebase.firestore.DocumentSnapshot) => {
          await removedDoc
            .collection(constants.FB_DISBURSEMENTS)
            .add(Object.assign({}, doc.data()));
          await doc.ref.delete();
        }
      )
    );

    const installmentsSnapshot = await loanInstallmentsDocumentRef
      .where('loanId', '==', loan.docId)
      .get();
    await Promise.all(
      installmentsSnapshot.docs.map(
        async (doc: firebase.firestore.DocumentSnapshot) => {
          await removedInstallmentsDocumentRef.add(
            Object.assign({}, doc.data())
          );
          await doc.ref.delete();
        }
      )
    );

    await this.collateralService.doRemoveLoanSecurities(adminUser, loan.docId);
    await loanDocumentRef.delete();
  }

  doAbortConfirmLoanAction = async (adminUser: firebase.User, loan: Loan) => {
    const loansCollection = this.afs.collection(constants.FB_LOANS);
    await loansCollection.doc(loan.docId).update({
      pendingAction: {},
      hasPendingAction: false,
      updatedBy: adminUser.uid,
      updatedByEmail: adminUser.email,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
    });

    loan.pendingAction = {};
    loan.pendingAction.isViewed = false;
    loan.status = 'abort_confirm_action';
    await this.historyService.addLoanHistoryItem(adminUser, loan.docId, loan);
  }

  doApproveRequest = async (
    adminUser: firebase.User | AdminUser,
    loan: Loan
  ) => {
    loan.status = LoanStatus.APPROVED;
    loan.approvedAt = firebase.firestore.FieldValue.serverTimestamp();
    await this.afs
      .collection(constants.FB_LOANS)
      .doc(loan.docId)
      .update({
        status: loan.status,
        approvedAt: loan.approvedAt,
        meta: {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: {
            uid: adminUser.uid,
            email: adminUser.email,
          },
        },
      });
    await this.historyService.addLoanHistoryItem(
      adminUser,
      loan.docId,
      loan,
      false
    );
  }

  doApproveAndMarkAsDisbursed = async (
    adminUser: firebase.User,
    loan: Loan
  ) => {
    loan.status = LoanStatus.DISBURSED;
    loan.approvedAt = firebase.firestore.FieldValue.serverTimestamp();
    loan.disbursedAt = firebase.firestore.FieldValue.serverTimestamp();
    await this.afs
      .collection(constants.FB_LOANS)
      .doc(loan.docId)
      .update({
        status: loan.status,
        approvedAt: loan.approvedAt,
        disbursedAt: loan.disbursedAt,
        meta: {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: {
            uid: adminUser.uid,
            email: adminUser.email,
          },
        },
      });

    loan.status = 'approved_and_disbursed';
    await this.historyService.addLoanHistoryItem(
      adminUser,
      loan.docId,
      loan,
      false
    );
  }

  doCommitteeApproveManualAdd = async (
    adminUser: firebase.User | AdminUser,
    loan: Loan
  ) => {
    await this.afs
      .collection(constants.FB_LOANS)
      .doc(loan.docId)
      .update({
        pendingAction: loan.pendingAction,
        meta: {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: {
            uid: adminUser.uid,
            email: adminUser.email,
          },
        },
      });

    loan.status = 'committee_approve_vote';
    await this.historyService.addLoanHistoryItem(adminUser, loan.docId, loan);
  }

  doRemovePendingAction = async (
    adminUser: firebase.User | AdminUser,
    loan: Loan
  ) => {
    loan.pendingAction = null;
    await this.afs
      .collection(constants.FB_LOANS)
      .doc(loan.docId)
      .update({
        pendingAction: loan.pendingAction,
        meta: {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: {
            uid: adminUser.uid,
            email: adminUser.email,
          },
        },
      });

    loan.status = 'remove_pending_action';
    await this.historyService.addLoanHistoryItem(adminUser, loan.docId, loan);
  }

  // MANUAL ACTIONS
  doMarkAsDisbursed = async (
    adminUser: firebase.User,
    loan: Loan,
    canDisburseLoans: boolean,
    transactionCode: string
  ) => {
    const loansCollection = this.afs.collection(constants.FB_LOANS);

    if (canDisburseLoans) {
      loan.status = LoanStatus.DISBURSED;
      loan.disbursedAt = firebase.firestore.FieldValue.serverTimestamp();

      await this.afs
        .collection(constants.FB_LOANS)
        .doc(loan.docId)
        .update({
          status: loan.status,
          dateDisbursed: loan.disbursedAt,
          meta: {
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedBy: {
              uid: adminUser.uid,
              email: adminUser.email,
            },
          },
        });

      await this.historyService.addLoanHistoryItem(
        adminUser,
        loan.docId,
        loan,
        true
      );
    } else {
      await loansCollection.doc(loan.docId).update({
        pendingAction: {
          type: LoanPendingAction.disburse,
          actionType: 'manual_disburse',

          doneBy: adminUser.uid,
          doneByEmail: adminUser.email,
          doneOn: firebase.firestore.FieldValue.serverTimestamp(),
        },
        actionType: 'manual_disburse',
        hasPendingAction: true,
        updatedBy: adminUser.uid,
        updatedByEmail: adminUser.email,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  }

  doConfirmMarkLoanDisbursed = async (
    adminUser: firebase.User | AdminUser,
    loan: Loan
  ) => {
    const loansCollection = this.afs.collection(constants.FB_LOANS);
    loan.status = LoanStatus.DISBURSED;
    loan.disbursedAt = firebase.firestore.FieldValue.serverTimestamp();

    await loansCollection.doc(loan.docId).update({
      status: loan.status,
      dateDisbursed: loan.disbursedAt,
      pendingAction: {},
      meta: {
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: {
          uid: adminUser.uid,
          email: adminUser.email,
        },
      },
    });

    loan.pendingAction = {};

    await this.historyService.addLoanHistoryItem(adminUser, loan.docId, loan);
  }

  doDeclineRequest = async (
    adminUser: firebase.User,
    loan: Loan,
    canConfirmActions: boolean
  ) => {
    const loanDocRef = this.afs.collection(constants.FB_LOANS).doc(loan.docId);
    loan.declinedAt = firebase.firestore.FieldValue.serverTimestamp();

    if (canConfirmActions) {
      await loanDocRef.update({
        status: LoanStatus.DECLINED,
        declinedAt: loan.declinedAt,
        meta: {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: {
            uid: adminUser.uid,
            email: adminUser.email,
          },
        },
      });
    } else {
      await loanDocRef.update({
        note: loan.note,
        pendingAction: {
          note: loan.note,
          type: LoanPendingAction.decline,
          actionType: loan.status,
          doneBy: adminUser.uid,
          doneByEmail: adminUser.email,
          doneOn: loan.declinedAt,
        },
        actionType: loan.status,
        hasPendingAction: true,
        updatedBy: adminUser.uid,
        updatedByEmail: adminUser.email,
        meta: {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: {
            uid: adminUser.uid,
            email: adminUser.email,
          },
        },
      });
    }

    loan.status = LoanStatus.MANUAL_DECLINE;
    await this.historyService.addLoanHistoryItem(
      adminUser,
      loan.docId,
      loan,
      true
    );
  }

  doConfirmLoanDecline = async (
    adminUser: firebase.User | AdminUser,
    loan: Loan
  ) => {
    loan.declinedAt = firebase.firestore.FieldValue.serverTimestamp();

    await this.afs
      .collection(constants.FB_LOANS)
      .doc(loan.docId)
      .update({
        status: LoanStatus.DECLINED,
        declinedAt: loan.declinedAt,
        meta: {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: {
            uid: adminUser.uid,
            email: adminUser.email,
          },
        },
        pendingAction: {},
        hasPendingAction: false,
        updatedBy: adminUser.uid,
        updatedByEmail: adminUser.email,
      });

    loan.pendingAction = {};
    loan.pendingAction.status = false;
    loan.status = LoanStatus.CONFIRM_MANUAL_DECLINE;
    await this.historyService.addLoanHistoryItem(
      adminUser,
      loan.docId,
      loan,
      false
    );
  }
}
