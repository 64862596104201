export class MpesaSentTransaction {
  api: string;
  docId?: string;
  status: string;
  loanId?: string;
  dateCreated: any;
  direction: string;
  ReferenceData: any;
  ResultCode: number;
  ResultDesc: string;
  ResultType: number;
  TransactionID: string;
  ConversationID: string;
  ReceiverPartyName: string;
  TransactionAmount: number;
  TransactionReceipt: string;
  ReceiverPartyMSISDN: string;
  ReceiverPartyPublicName: string;
  OriginatorConversationID: string;
  TransactionCompletedDateTime: string;
  B2CUtilityAccountAvailableFunds: number;
  B2CWorkingAccountAvailableFunds: number;
  B2CRecipientIsRegisteredCustomer: string;
  B2CChargesPaidAccountAvailableFunds: number;
}
