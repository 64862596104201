import { constants } from '../constants';

export class Meta {
  createdAt: any;
  createdBy: DoneBy;
  updatedAt: any;
  updatedBy: DoneBy;
  deletedAt: any;
  deletedBy: DoneBy;
}

export class Address {
  country: string;
  county?: string;
  town: string;
  street: string;
  postalAddress?: string;
}

export class DocumentPath {
  faceImage?: string;
  idFrontImage?: string;
  idBackImage?: string;
}

export class DoneBy {
  uid: string;
  email?: string;
  phoneNumber?: string;
  name?: string;
}

export class Qualified {
  status: boolean;
  reason: string;
  date?: any;
  actor?: DoneBy;
}

export class Transaction {
  amount: string;
  source: string;
  code: string; // pesalink, check number
}

export class Progression {
  cap: number = constants.LOAN_PROGRESSION_CAP;
  amount: number = constants.LOAN_PROGRESSION_AMOUNT;
}

export class DebtCollector {
  docId: string;
  email: string;
  names: string;
}

// export class PendingAction {
//   status?: boolean;
//   isViewed?: boolean;
//   type?: string;
//   doneBy?: string;
//   doneByEmail?: string;
//   doneOn?: any;
//   canApprove?: string[];
//   minApprovingCount?: number;
//   approvedBy?: any[];
//   action?: string;
// }

export class Restructured {
  principal: number;
  interest: number;
  status: boolean;
  period: number;
  amount: number;
}

export class FollowUps {
  calls: number;
  emails: number;
  total: number;
  lastFollowUpDate?: any;
  comment?: string;
}

export class BankDetails {
  name = '';
  code = '';
  phoneNumber = '';
  accountNumber = '';
}

export class ClientDetails {
  sex: string;
  dob: string;
  type: string;
  owner: string;
  firstName: string;
  lastName: string;
  middleName: string;
  county: string;
  idType: string;
  clientId: string;
  idNumber: string;
  phoneNumber: string;
}

export class Guarantor {
  type: string;
  name: string;
  docId: string;
  amount: number;
  description: string;
}

export class EarlyRepayment {
  interest?: number;
  amountToRepay?: number;
  interestToRepay?: number;
  principalToRepay?: number;
}

export class TopUps {
  amount: number;
}

export class InitialAmounts {
  interest?: number;
  amountToRepay?: number;
  interestToRepay?: number;
  principalToRepay?: number;
}

export class CrbData {
  results: any;
  consent: boolean;
  consentDate: any;
}

export class DeviceInfo {
  info: any;
  isRooted: boolean;
  UUID?: string;
}

export class Employment {
  status: string; // self / employed / unemployed
  employer?: string;
}

export class MobileMoney {
  phoneNumber: string;
  name: string;
}

export class Fraud {
  status = false;
  reason = '';
  date?: string;
}

export class WatchList {
  status = false;
  reason = '';
  date?: any;
  actor?: DoneBy;
}
