export class GeneralSettings {

  docId: string;
  currency = 'KES';
  phonePrefix = '254';
  timezone = 'Africa/Nairobi';

  updatedAt: any;
  updatedBy: string;
  updatedByEmail: string;

  createdAt: any;
  createdBy: any;
  createdByEmail: string;

}

export class ClientSettings {

  docId: string;
  checkCrb = false;
  phonePrefix = '254';
  sendActivationMessage = false;

  checkCrbOn = 'onCreate';

  updatedAt: any;
  updatedBy: string;
  updatedByEmail: string;

  createdAt: any;
  createdBy: any;
  createdByEmail: string;

}

export class LoanTypes {

  docId: string;
  name: string;
  disburseTo: string;
  type = 'web';
  isActive = false;
  maxNoOfLoans = 1;

  updatedAt: any;
  updatedBy: string;
  updatedByEmail: string;

  createdAt: any;
  createdBy: any;
  createdByEmail: string;
}

export class LoansSettings {

  docId: string;

  principle: PrincipleSettings = new PrincipleSettings();
  period: PeriodSettings = new PeriodSettings();
  interest: InterestSettings = new InterestSettings();
  processingFee: ProcessingFeeSettings = new ProcessingFeeSettings();
  insurance: InsuranceSettings = new InsuranceSettings();
  penalty: PenaltySettings = new PenaltySettings();
  security: SecuritySettings = new SecuritySettings();
  repayment: RepaymentSettings = new RepaymentSettings();

  currency = 'KES';
  active = true;
  maxNoOfLoans = 1;

  lastLoanDate?: any;

  updatedAt: any;
  updatedBy: string;
  updatedByEmail: string;

  createdAt: any;
  createdBy: any;
  createdByEmail: string;

}

class PrincipleSettings {
  max: number;
  min: number;
}

class PeriodSettings {
  type: string; // week , month , year
  min: number;
  max: number;
}

class InterestSettings {
  rate: number;
  period: string; // Daily, Weekly, Monthly, Yearly.
  min: number;
  max: number;
  type: string; // reducing balance or simple
}

class ProcessingFeeSettings {
  fee: number;
  type: string; // amount , rate
  deductAt: string; // disbursement, paidInAdvance, lumped up in loan
}
class InsuranceSettings {
  fee: number;
  type: string;
  deductAt: string; // disbursement, paidInAdvance, lumped up in loan
}

class PenaltySettings {
  type: string; // fixed rate, amount
  rate: number;
  base: string; // principle or amount to repay
  addToInterest = false; // for reducing balance loans
}

class SecuritySettings {
  secured = false;
  types?: string[];
}

class RepaymentSettings {
  repayment?: string[];
}
