import { LoanStatus } from './../enums/loan.enums';
export class Instalment {

  docId?: string;
  dueDate: any;
  status: string = LoanStatus.PENDING;
  owner: string;
  loanId: string;
  payment: number;
  client: Client;
  createdAt: any;
  lastUpdated: any;
  principle: number;
  clientType: string;
  amountPaid: number; // no payment
  description: string;
  paymentNumber: number;
  amountToRepay: number;
  transactionCode?: string;
  paymentToInterest: number;
  paymentToPrinciple: number;
  accumulatedInterest: number;

  committedAt: any; // TODO move to installment

}

class Client {
  uid: string;
  firstName: string;
  lastName: string;
  middleName: string;
  phoneNumber: string;
  type: string;
  email: string;
}
