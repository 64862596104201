
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { constants } from 'src/app/shared/constants';
import { UserNotification } from '../models/user.notification.model';
import * as moment from 'moment';

@Injectable()
export class UserNotificationsService {

    constructor(
        private afs: AngularFirestore
    ) { }

    getNotificationByUid(uid: string): Observable<any> {
        return this.afs.collection(constants.FB_CLIENTS_NOTIFICATIONS).doc(uid).snapshotChanges().pipe(
            map(a => {
                if (a.payload.exists) {
                    const notification = a.payload.data() as UserNotification;
                    const docId = a.payload.id;
                    return { docId, ...notification };
                } else {
                    return null;
                }
            })
        );
    }

    getByNotificationByNotificationNumber = async (notificationNo: string) => {
        const userNotificationCollection = firebase.firestore().collection(constants.FB_CLIENTS_NOTIFICATIONS);
        const snapshot = await userNotificationCollection
            .where('notificationNo', '==', notificationNo)
            .get();
        return snapshot.docs[0].id;
    }

    save(adminUser: firebase.User, notification: UserNotification): Promise<any> {
        return this.afs.collection(constants.FB_CLIENTS_NOTIFICATIONS).add({
            notificationNo: notification.notificationNo,
            notificationType: notification.notificationType,
            status: notification.status,
            sendTo: notification.sendTo,
            message: notification.message,
            loanAge: notification.loanAge || null,
            partialPayment: notification.partialPayment || null,
            sendTime: moment(notification.sendTime, 'HH:mm').format('HH:mm:ss'),
            startDate: moment(notification.startDate.setHours(0, 0, 0, 0)).toDate(),
            endDate: moment(notification.endDate.setHours(23, 59, 59, 999)).toDate(),

            sunday: notification.sunday || false,
            monday: notification.monday || false,
            tuesday: notification.tuesday || false,
            wednesday: notification.wednesday || false,
            thursday: notification.thursday || false,
            friday: notification.friday || false,
            saturday: notification.saturday || false,

            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: adminUser.uid,
            createdByEmail: adminUser.email,

            isDeleted: false
        });
    }

    update = async (adminUser: firebase.User, notificationId: string, notification: UserNotification): Promise<any> => {
        const userNotificationsRef = this.afs.collection(constants.FB_CLIENTS_NOTIFICATIONS);
        const data: any = {
            notificationType: notification.notificationType,
            status: notification.status,
            sendTo: notification.sendTo,
            message: notification.message,
            loanAge: notification.loanAge || null,
            partialPayment: notification.partialPayment || null,
            sendTime: moment(notification.sendTime, 'HH:mm').format('HH:mm:ss'),
            startDate: moment(notification.startDate.setHours(0, 0, 0, 0)).toDate(),
            endDate: moment(notification.endDate.setHours(23, 59, 59, 999)).toDate(),

            sunday: notification.sunday || false,
            monday: notification.monday || false,
            tuesday: notification.tuesday || false,
            wednesday: notification.wednesday || false,
            thursday: notification.thursday || false,
            friday: notification.friday || false,
            saturday: notification.saturday || false,

            dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            updatedBy: adminUser.uid,
            updatedByEmail: adminUser.email
        };
        try {
            await userNotificationsRef.doc(notificationId).update(data);
        } catch (error) {
            console.log(error);
        }
    }

    delete = async (adminUser: firebase.User, notificationId: string, notification: UserNotification): Promise<any> => {
        const userNotificationsRef = this.afs.collection(constants.FB_CLIENTS_NOTIFICATIONS);
        const data: any = {
            isDeleted: true,

            dateDeleted: firebase.firestore.FieldValue.serverTimestamp(),
            deletedBy: adminUser.uid,
            deletedByEmail: adminUser.email
        };
        try {
            await userNotificationsRef.doc(notificationId).update(data);
        } catch (error) {
            console.log(error);
        }
    }

    updateViewedBy = async (adminUser: firebase.User, notificationId: string, notification: UserNotification): Promise<any> => {
        const userNotificationsRef = this.afs.collection(constants.FB_CLIENTS_NOTIFICATIONS);
        const data: any = {
            dateViewed: firebase.firestore.FieldValue.serverTimestamp(),
            lastViewBy: adminUser.uid,
            lastViewByEmail: adminUser.email
        };
        try {
            await userNotificationsRef.doc(notificationId).update(data);
        } catch (error) {
            console.log(error);
        }
    }

}
