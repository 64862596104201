import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { constants } from 'src/app/shared/constants';
import { Auth } from '../models/auth.model';
import { AdminDevice } from '../models/admin.model';
import { take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';

@Injectable()
export class AuthService {
    private authState: any = null;

    constructor(
        private afs: AngularFirestore,
        private afAuth: AngularFireAuth
    ) {
        this.afAuth.authState.subscribe((auth) => {
            this.authState = auth;
        });
    }

    isLoggedIn = (): boolean => {
        return this.authState !== null;
    }

    isAdminUser = async (uid: string): Promise<boolean> => {
        const adminsCollection = this.afs.collection(constants.FB_ADMINS);
        const doc = await adminsCollection.doc(uid).ref.get();
        return doc.exists;
    }

    doLoginByEmail = async (auth: Auth) => {
        try {
            const userAuth = await this.afAuth.signInWithEmailAndPassword(auth.email, auth.password);
            if (userAuth && userAuth.user) {
                const token = await firebase.auth().currentUser.getIdTokenResult();
                if (token.claims.isAdmin) {
                    const isSuspended = token.claims.isSuspended || false;
                    if (isSuspended) {
                        await this.afAuth.signOut();
                        return {
                            valid: false,
                            error: 'User account is NOT authorized to access this service'
                        };
                    } else {
                        return {
                            valid: true,
                            user: userAuth.user
                        };
                    }
                } else {
                    await this.afAuth.signOut();
                    return {
                        valid: false,
                        error: 'User account is NOT authorized to access this service'
                    };
                }
            } else {
                return {
                    valid: false,
                    error: 'User account is NOT authorized to access this service'
                };
            }
        } catch (error) {
            return {
                valid: false,
                error
            };
        }
    }

    sendPasswordResetEmail = async (email: string) => {
        try {
            await this.afAuth.sendPasswordResetEmail(email);
            return {
                status: true
            };
        } catch (error) {
            return {
                status: false,
                error
            };
        }
    }

    isOnAKnownDevice = async (uid: string): Promise<boolean> => {
        const deviceInfo: AdminDevice = this.getDeviceInfo();
        const adminsCollection = this.afs.collection(constants.FB_ADMINS);
        const adminDevicesCollection = adminsCollection.doc(uid).collection('devices');
        const knownDevices = await adminDevicesCollection.valueChanges().pipe(take(1)).toPromise();
        const isAKnownDevice: boolean = knownDevices.filter(device => deviceInfo.isEquals(device)).length > 0;
        if (!isAKnownDevice) {
            // Hack; add the unknown device for now
            const data = JSON.parse(JSON.stringify(deviceInfo));
            await adminDevicesCollection.add(data);
        }
        return isAKnownDevice;
    }




    /* Creates GUID for user based on several different browser variables
       * It will never be RFC4122 compliant but it is robust
       * */
    private getDeviceInfo(): AdminDevice {
        const nav = window.navigator;
        const screen = window.screen;
        let guid: any = nav.mimeTypes.length;
        guid += nav.plugins.length;
        guid += `${screen.width || 0}${screen.height || 0}`;
        guid += screen.pixelDepth || 10;
        return new AdminDevice({
            guid: +guid,
            screenWidth: screen.width || '',
            screenHeight: screen.height || '',
            screenPixelDepth: screen.pixelDepth || '',
            userAgent: nav.userAgent
        });
    }
}
