import { constants } from '../constants';
import {
  Address,
  BankDetails,
  CrbData,
  DeviceInfo,
  DocumentPath,
  Employment,
  Fraud,
  MobileMoney,
  Progression,
  Qualified,
  WatchList,
} from './common.models';

export class Client {
  docId: string;
  email?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  phoneNumber: string;
  idNumber: string;
  idDocType: string;
  payrollNumber?: string;
  type = 'person';
  owner = constants.DEFAULT_OWNER;
  otherContactInfo: any[];

  occupation?: string;
  bank: BankDetails = new BankDetails();
  dob: any;
  sex: string;
  company?: string; // company name and phone number

  address?: Address = new Address();

  documentPath: DocumentPath = new DocumentPath();

  // other documents
  otherDocuments: any[];

  loanLimit: number;

  progression?: Progression = new Progression();
  qualified?: Qualified = new Qualified();

  hasReachedCap = false; // progression cap
  hasAScoreCard = false;
  hasReadSmsInbox = false;
  wasAskedToReview = false;

  watchList?: WatchList = new WatchList();

  createdAt: any;
  updatedAt: any;
  registeredAt: any;
  lastReadSmsAt: any;

  notificationsCount = 0;

  appVersion: string;
  networkInfo: any;
  location: any;

  deviceInfo?: DeviceInfo = new DeviceInfo();
  crb?: CrbData = new CrbData();

  employment: Employment = new Employment();
  fcmToken: string;
  imsiData: any; // add actual data map

  maxLoanPeriod: any;
  lastDisbursedDate: any;
  fraud?: Fraud;

  mobileMoney: MobileMoney = new MobileMoney();
  pendingAction: any;
  hasPendingAction: boolean;
  visionAPIResult: any;
}

export class UserScoreCard {
  clientId: string;
  isFraud = false;
  emailLast3LocalPartIsNumber = false;
  phoneNumberSubString: string;
  diffLastMpesaMsgAndDateRegistered = 0;
  hourCreated = 0;
  dayOfWeekCreated = 0;
  idNumberSubString = 0;
  age = 0;
  sex: string;
  occupation: string;
  distanceFromCityCenter = 0;
  connectionType: string;
  ssid: string;
  manufacturer: string;
  model: string;
  county: string;
  residentialTown: string;
  bankName: string;
  willDefaultFirstLoan = false;
  willDefaultFirstLoanProbability = 0;
  lifeTimeEarningWillSurpassLimit = false;
  lifeTimeEarningWillSurpassProbability = 0;
  avMpesaMsgsPerDay = 0;
  mpesaCount = 0;
  lastMpesaDate: any;
  firstMpesaDate: any;
  difflastMpesaMsgFirstMpesaMsg = 0;
  inBoundTransactions = 0;
  outBoundTransactions = 0;
  inboundOutboundTransactions = 0;
  remittanceWaveMoney = 0;
  remittanceWesterUnionMoney = 0;
  remittanceWorldRemitMoney = 0;
  remittanceMpesaGlobalMoney = 0;
  mpesaAgentWithdrawals = 0;
  mpesaAgentDeposits = 0;
  lipaNaMpesaReceipts = 0;
  sentSafaricomMobileMoney = 0;
  sentAirtelMobileMoney = 0;
  receivedSafaricomMobileMoney = 0;
  receivedAirtelMobileMoney = 0;
  safaricomPostPaidMobileServices = 0;
  safaricomAirtimeMobileServices = 0;
  pesaPalMobileServices = 0;
  safaricomHomeFaibaISPServices = 0;
  zukuFaibaISPServices = 0;
  jamiiTelcomISPServices = 0;
  venditKplcUtilityServices = 0;
  kplcUtilityServices = 0;
  waterCompanyUtilityServices = 0;
  goTVServices = 0;
  c2BDSTVServices = 0;
  c2BZUKUTVServices = 0;
  c2BStartimesTVServices = 0;
  c2BKcbBank = 0;
  c2BchaseBank = 0;
  c2BStandardCharteredBank = 0;
  c2BCoopBankCollectionAcc = 0;
  c2BCoopBankMoneyTransfer = 0;
  c2BnbkBank = 0;
  c2BsbmBank = 0;
  c2BpostOfficeSavingsBank = 0;
  c2BequityBank = 0;
  c2BhFCBank = 0;
  c2BstanbicBank = 0;
  c2BsgulfAfricanBank = 0;
  c2BsidianBank = 0;
  c2BNCBABank = 0;
  c2BViaBank = 0;
  coopBankb2c = 0;
  bankOfAfricaBankb2c = 0;
  familyBankPesaPapBankb2c = 0;
  b2CbarclaysBank = 0;
  nbkBankBankb2c = 0;
  barclaysBankKLtdBankb2c = 0;
  consolidatedBankb2c = 0;
  ncbaBankb2c = 0;
  hfcBankb2c = 0;
  equityBankb2c = 0;
  stanbicBankb2c = 0;
  standardCharteredBankb2c = 0;
  loopBankb2c = 0;
  saccob2c = 0;
  saccoc2b = 0;
  kCBMpesaLoanc2b = 0;
  mswhariLoanc2b = 0;
  oKashLoanc2b = 0;
  branchLoanc2b = 0;
  timizaLoanc2b = 0;
  stawikaLoanc2b = 0;
  talaLoanc2b = 0;
  harakaLoanc2b = 0;
  zenkaLoanc2b = 0;
  berryLoanc2b = 0;
  finServeLoanc2b = 0;
  hfcWhizzLoanc2b = 0;
  ubaPesaLoanc2b = 0;
  mswhariLoanb2c = 0;
  kcbMpesaLoanb2c = 0;
  oKashLoanb2c = 0;
  branchLoanb2c = 0;
  timizaLoanb2c = 0;
  stawikaLoanb2c = 0;
  talaLoanb2c = 0;
  harakaLoanb2c = 0;
  zenkaLoanb2c = 0;
  berryLoanb2c = 0;
  finserveMkeyLoanb2c = 0;
  hfWhizzLoanb2c = 0;
  ubaPesaLoanb2c = 0;
  sportPesaBetsc2b = 0;
  betikaBetsc2b = 0;
  betWayBetsc2b = 0;
  pesa22Betsc2b = 0;
  sportyBetsc2b = 0;
  betLionBetsc2b = 0;
  odiBetsBetsc2b = 0;
  mozzartBetsc2b = 0;
  eaziBetsc2b = 0;
  shabikiBetsc2b = 0;
  powerBetsc2b = 0;
  betBossBetsc2b = 0;
  miliBetsBetsc2b = 0;
  betinBetsc2b = 0;
  gamingInternationalBetsc2b = 0;
  eliteBetsc2b = 0;
  helaBetsc2b = 0;
  gameManiaBetsc2b = 0;
  shellFuelc2b = 0;
  totalFuelc2b = 0;
  lakeOilFuelc2b = 0;
  gulfFuelc2b = 0;
  kenolFuelc2b = 0;
  oilLibyaFuelc2b = 0;
  kobilFuelc2b = 0;
  toshaFuelc2b = 0;
  petrocityFuelc2b = 0;
  hassPetroleumFuelc2b = 0;
  astrolFuelc2b = 0;
  luqmanFuelc2b = 0;
  midaxFuelc2b = 0;
  rushabFuelc2b = 0;
  rivaFuelc2b = 0;
  broadwayFuelc2b = 0;
  reyhanFuelc2b = 0;
  shreehariFuelc2b = 0;
  eldoretFuelc2b = 0;
  energyCompaniesc2b = 0;
  carrefourSupermarketc2b = 0;
  choppiesSupermarketc2b = 0;
  cleanshelfSupermarketc2b = 0;
  mathaiSupermarketc2b = 0;
  quickmartSupermarketc2b = 0;
  tuskysSupermarketc2b = 0;
  naivasSupermarketc2b = 0;
  chandaranaSupermarketc2b = 0;
  powerstarSupermarketc2b = 0;
  uchumiSupermarketc2b = 0;
  kassmattSupermarketc2b = 0;
  eldoretmattSupermarketc2b = 0;
  allSupermarketc2b = 0;
  clubsAlcoholicJointsc2b = 0;
  barsAlcoholicJointsc2b = 0;
  pubsAlcoholicJointsc2b = 0;
  restrauntsAlcoholicJointsc2b = 0;
  cafesFoodJointsc2b = 0;
  pizzariasFoodJointsc2b = 0;
  javaHousesFoodJointsc2b = 0;
  coffeeHousesFoodJointsc2b = 0;
  foodEateriesFoodJointsc2b = 0;
  bakeriesFoodJointsc2b = 0;
  hotelsAccomodationc2b = 0;
  allLodgesAccomodationc2b = 0;
  beachHotelsAccomodationc2b = 0;
  barbersBeautyCarec2b = 0;
  salonsBeautyCarc2b = 0;
  hardwaresConstructionc2b = 0;
  garagesAutomotivec2b = 0;
  nhifHealthCarec2b = 0;
  insurancec2b = 0;
  assurancec2b = 0;
  moneymarketsc2b = 0;
  investmentsc2b = 0;
  hospitalsHealthCarec2b = 0;
  clinicsHealthCarec2b = 0;
  dispensariesHealthCarec2b = 0;
  pharmaciesHealthCarec2b = 0;
  veterinariesc2b = 0;
  schoolPaymentsc2b = 0;
  universityPaymentsc2b = 0;
  schoolFeesc2b = 0;
  universityFeesc2b = 0;
  churchPaymentsc2b = 0;
  nairobiBusinessesc2b = 0;
  mombasaBusinessesc2b = 0;
  kisumuBusinessesc2b = 0;
  nakuruBusinessesc2b = 0;
  machakosBusinessesc2b = 0;
  kakamegaBusinessesc2b = 0;
  thikaBusinessesc2b = 0;
  eldoretBusinessesc2b = 0;
  diffLastMpesaMsgFirstMpesaMsg = 0;
  c2BIPayLtd = 0;
  b2CIPayLtd = 0;
  whizzLoanb2c = 0;
  kfcFoodJointsc2b = 0;
}

export class PhoneNumber {
  country: string;
  area: string;
  prefix: string;
  line: string;

  // format phone numbers as E.164
  get e164(): string {
    const num = this.line;
    return `+${num}`;
  }
}
